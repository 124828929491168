import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import styles from './EventRunningBanner.module.less';

export const EventRunningBanner = ({ className, sx }: { className?: string; sx?: SxProps }) => (
  <Box
    className={clsx(styles.shine, styles.eventRunningBanner)}
    sx={{ mt: 1, mb: 2, p: 1, borderRadius: 2, ...sx }}
  >
    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
      EVENT IS RUNNING
    </Typography>
  </Box>
);
