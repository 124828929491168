import { ContentBox } from '../../../components/ContentBox';
import { Title } from 'react-admin';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom
} from '@mui/material';
import dayjs from 'dayjs';
import { Loader } from '../../../../../components/loader/Loader';
import Markdown from 'react-markdown';
import utc from 'dayjs/plugin/utc';
import { EventRegistration } from './EventRegistration';
import { SimpleModal } from '../../../components/modal/Modal';
import { TitleBox } from '../../../components/title-box/TitleBox';
import styles from './ViewEvent.module.less';
import Campaign from '@mui/icons-material/Campaign';
import Schedule from '@mui/icons-material/Schedule';
import Late from '@mui/icons-material/Schedule';
import Timer from '@mui/icons-material/TimerOutlined';
import ViewList from '@mui/icons-material/ViewList';
import Location from '@mui/icons-material/LocationOn';
import Register from '@mui/icons-material/HowToReg';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Pencil from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Tentative from '@mui/icons-material/HelpOutline';
import { useGetUserProfileQuery } from '../../../../../store/api/User';
import clsx from 'clsx';
import { DiscordEmoji } from '../../../../../components/discord-emoji/DiscordEmoji';
import { trpc } from '../../../api/trpc';
import { RouterOutputs } from '../../../../../../server/AppRouter';
import { EventRunningBanner } from '../../../components/event-running-banner/EventRunningBanner';
import { usePerms } from '../../../../../hooks/usePerms';
import { hasEntityPermissions, Permissions } from '../../../../../../shared/Permissions';

dayjs.extend(utc);

export const ViewEvent = () => {
  const { id } = useParams() as { id: string };
  const event_id = parseInt(id, 10);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { data: event, isPending, isError } = trpc.events.getEvent.useQuery({ event_id });
  const deleteEvent = trpc.events.deleteEvent.useMutation();

  if (isPending) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <ContentBox>
      {event.started_time && !event.ended_time && <EventRunningBanner />}
      <Title title={event.name} />
      <EventView
        event={event}
        onDeleteClicked={() => {
          setIsDeleting(true);
        }}
      />
      <SimpleModal
        open={searchParams.get('register') === 'true'}
        width={350}
        onClose={() => {
          searchParams.delete('register');
          setSearchParams(searchParams);
        }}
      >
        <EventRegistration event_id={event.id} />
      </SimpleModal>
      <Dialog open={isDeleting} onClose={() => setIsDeleting(false)}>
        <DialogTitle>Delete Event</DialogTitle>
        <DialogContent>Are you sure you want to delete this event?</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              await deleteEvent.mutateAsync({ event_id });
              navigate('/dashboard/events');
            }}
          >
            Delete
          </Button>
          <Button variant="contained" color="primary" onClick={() => setIsDeleting(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </ContentBox>
  );
};

const EventView = ({
  event,
  onDeleteClicked
}: {
  event: RouterOutputs['events']['getEvent'];
  onDeleteClicked: () => void;
}) => {
  const eventStarted = !!event.started_time;
  const { data: user } = useGetUserProfileQuery(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const { id, description, start_time, duration } = event;
  const navigate = useNavigate();
  const permissions = usePerms();

  const {
    data: participants,
    isLoading: participantsLoading,
    isError: participantsError
  } = trpc.events.getParticipants.useQuery(
    { event_id: event.id },
    {
      refetchInterval: 5000
    }
  );

  if (!participants || participantsLoading) {
    return null;
  }

  if (participantsError) {
    return null;
  }

  const registrationData = participants.find(participant => participant.user_id === user?.id);

  return (
    <Box>
      <TitleBox style={{ marginBottom: '10px' }}>
        <h1>{event.name}</h1>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row'
          }}
          spacing={1}
          alignItems={{
            xs: 'stretch',
            sm: 'center'
          }}
          justifyContent="flex-end"
          sx={{
            width: {
              xs: '100%',
              sm: 'auto'
            },
            pb: {
              xs: 1,
              sm: 0
            }
          }}
        >
          {hasEntityPermissions(
            permissions,
            Permissions.RUN_OWN_EVENT,
            Permissions.RUN_ANY_EVENT,
            event.shotcaller.id === user?.id
          ) && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<PlayArrow />}
              onClick={() => {
                navigate(`/dashboard/events/${id}/run`);
              }}
            >
              Run Event
            </Button>
          )}
          {hasEntityPermissions(
            permissions,
            Permissions.EDIT_OWN_EVENT,
            Permissions.EDIT_ANY_EVENT,
            event.shotcaller.id === user?.id
          ) && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              startIcon={<Pencil />}
              onClick={() => {
                navigate(`/dashboard/events/${id}/edit`);
              }}
            >
              Edit Event
            </Button>
          )}
          {hasEntityPermissions(
            permissions,
            Permissions.DELETE_OWN_EVENT,
            Permissions.DELETE_ANY_EVENT,
            event.shotcaller.id === user?.id
          ) && (
            <Button
              variant="contained"
              size="small"
              color="secondary"
              startIcon={<Delete />}
              onClick={onDeleteClicked}
            >
              Delete Event
            </Button>
          )}
        </Stack>
      </TitleBox>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            md: 'max-content 1fr'
          },
          gap: '20px'
        }}
      >
        <Box
          sx={{
            flexShrink: 0
          }}
        >
          {eventStarted ? (
            <Button variant="contained" fullWidth color="primary" disabled startIcon={<Register />}>
              Registration Closed
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              color="primary"
              startIcon={<Register />}
              onClick={() => {
                searchParams.set('register', 'true');
                setSearchParams(searchParams);
              }}
              className={clsx({ [styles.shine]: !registrationData })}
            >
              {registrationData ? 'Update Registration' : 'Register'}
            </Button>
          )}
          <Paper elevation={1} sx={{ padding: '5px 10px', marginTop: '10px' }}>
            <Table size="small">
              <TableBody>
                {event.shotcaller && (
                  <TableRow>
                    <TableCell sx={{ padding: '3px' }}>
                      <Stack alignItems="center">
                        <Campaign />
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ padding: '12px 6px' }}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar
                          src={event.shotcaller.avatar || ''}
                          sx={{ width: 24, height: 24 }}
                        />
                        <span>{event.shotcaller.albion_name}</span>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell sx={{ padding: '3px' }}>
                    <Stack alignItems="center">
                      <ViewList />
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ padding: '12px 6px' }}>
                    {event.comp?.id && event.comp.id !== 1 ? (
                      <Link to={`/dashboard/builds/${event.comp.id}`}>{event.comp.name}</Link>
                    ) : (
                      'Any Builds'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: '3px' }}>
                    <Stack alignItems="center">
                      <Schedule />
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ padding: '12px 6px' }}>
                    {dayjs(start_time).utc(true).local().format('dddd, MMMM D @ h:mm A')}
                  </TableCell>
                </TableRow>
                {duration && (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ padding: '3px' }}>
                      <Stack alignItems="center">
                        <Timer />
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ padding: '12px 6px' }}>{duration} minutes</TableCell>
                  </TableRow>
                )}
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ padding: '3px' }}>
                    <Stack alignItems="center">
                      <Location />
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ padding: '12px 6px' }}>
                    {event.mass_location || 'Undisclosed'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Box>

        <Box style={{ maxWidth: '800px' }}>
          <Typography variant="body1" component="div">
            <Markdown>{description}</Markdown>
          </Typography>

          <br />

          <Paper
            elevation={0}
            sx={{ padding: 1, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          >
            <h3>
              Regears:{' '}
              {event.regear_rules_id !== 1 || event.other_regear_rules ? (
                <span style={{ color: 'lime' }}>Yes</span>
              ) : (
                <span style={{ color: 'red' }}>No</span>
              )}
            </h3>
          </Paper>
          <Typography
            variant="body1"
            component={Paper}
            sx={{ padding: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          >
            <Markdown>
              {event.regear_rule?.description || event.other_regear_rules || 'No Regears'}
            </Markdown>
          </Typography>

          <br />

          <Paper
            elevation={0}
            sx={{ padding: 1, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          >
            <h3>Loot Split Rules: {event.loot_split_rule?.name}</h3>
          </Paper>
          <Typography
            variant="body1"
            component={Paper}
            sx={{ padding: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          >
            <Markdown>{event.loot_split_rule?.description || 'No Loot Split Rules'}</Markdown>
          </Typography>
        </Box>
      </Box>

      <PlayerSectionTitle title="Player Roster" />
      <PlayerGrid>
        {event.roles.map(role => (
          <Box key={role.id} sx={{ minWidth: '30%' }}>
            <Stack direction="row" spacing={1} component={Paper}>
              <DiscordEmoji id={role.icon || '1135300543024205894'} size={24} />
              <span>{role.name}</span>
            </Stack>
            <List dense>
              {participants
                .filter(player => player.role?.id === role.id)
                .map(player => (
                  <Player
                    key={player.user_id}
                    playerName={player.name}
                    avatar={player.user?.displayAvatarURL}
                    tentative={player.tentative}
                    late={player.late}
                    attended={eventStarted && player.attended}
                    registered={player.registered}
                    build={player.build}
                    eventStarted={eventStarted}
                  />
                ))}
            </List>
          </Box>
        ))}
        <Box sx={{ minWidth: '30%' }}>
          <Stack direction="row" spacing={1} component={Paper}>
            <DiscordEmoji id="1135300543024205894" size={24} />
            <span>Unknown</span>
          </Stack>
          <List dense>
            {participants
              .filter(player => !player.role?.id)
              .map(player => (
                <Player
                  key={player.user_id}
                  playerName={player.name}
                  avatar={player.user?.displayAvatarURL}
                  tentative={player.tentative}
                  late={player.late}
                  attended={eventStarted && player.attended}
                  registered={player.registered}
                  eventStarted={eventStarted}
                />
              ))}
          </List>
        </Box>
      </PlayerGrid>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '40px'
        }}
      >
        {event.image_url && <img src={event.image_url} alt={event.name} className={styles.image} />}
      </Box>
    </Box>
  );
};

const Player = ({
  playerName,
  avatar,
  tentative,
  late,
  attended,
  registered,
  eventStarted,
  build
}: {
  playerName: string;
  avatar?: string | null;
  tentative: boolean;
  late: boolean;
  attended: boolean;
  registered: boolean;
  eventStarted: boolean;
  build?: RouterOutputs['events']['getParticipants'][number]['build'];
}) => {
  if (!attended && !registered) {
    return null;
  }

  return (
    <TooltipIfAbsent show={eventStarted && !attended} title="No Show">
      <ListItem
        component={Paper}
        elevation={2}
        sx={{
          backgroundImage:
            !eventStarted && tentative && late
              ? 'linear-gradient(45deg, rgba(98,88,27,0.55), rgba(66,27,98,0.55))'
              : undefined,
          backgroundColor:
            eventStarted && !attended
              ? 'rgba(98,27,27,0.55)'
              : tentative
              ? 'rgba(98,88,27,0.55)'
              : late
              ? 'rgba(66,27,98,0.55)'
              : undefined,
          opacity: eventStarted && !attended ? 0.3 : 1,
          borderBottom: '1px solid #444444',
          position: 'relative',
          borderRadius: 0,
          '&:last-child': { borderBottom: 'none' }
        }}
      >
        {build && (
          <ListItemAvatar>
            <Avatar variant="square" sx={{ bgcolor: 'transparent', width: 45, height: 45 }}>
              <img src={build.icon} alt={build.name} />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText primary={playerName} secondary={build?.name || 'Weapon Not Selected'} />
        <ListItemSecondaryAction>
          <Stack direction="row" spacing={0.2}>
            {tentative && (
              <Tooltip title="Tentative" placement="top" arrow TransitionComponent={Zoom}>
                <Tentative />
              </Tooltip>
            )}
            {late && (
              <Tooltip title="Late" placement="top" arrow TransitionComponent={Zoom}>
                <Late />
              </Tooltip>
            )}
            <Avatar
              src={avatar || ''}
              sx={{
                width: 24,
                height: 24,
                opacity: eventStarted && !attended ? 0.3 : 1
              }}
            />
          </Stack>
        </ListItemSecondaryAction>
      </ListItem>
    </TooltipIfAbsent>
  );
};

const PlayerGrid = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: {
        xs: '1fr',
        sm: '1fr',
        md: '1fr 1fr',
        lg: '1fr 1fr 1fr',
        xl: '1fr 1fr 1fr 1fr'
      },
      gap: '20px'
    }}
  >
    {children}
  </Box>
);

const PlayerSectionTitle = ({ title }: { title: string }) => (
  <h2
    style={{
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '10px',
      padding: '5px',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px'
    }}
  >
    {title}
  </h2>
);

const TooltipIfAbsent = ({
  title,
  children,
  show
}: {
  title: string;
  children: React.ReactElement<any, any>;
  show: boolean;
}) => {
  return show ? (
    <Tooltip title={title} placement="top" arrow followCursor TransitionComponent={Zoom}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
