import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const user = createApi({
  reducerPath: 'user',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/user' }),
  endpoints: builder => ({
    getUserProfile: builder.query({
      query: () => 'profile'
    })
  })
});

export const { useGetUserProfileQuery } = user;
