import React from 'react';
import styles from './layout.module.less';
import { Link } from 'react-router-dom';
import { useGetUserProfileQuery } from '../store/api/User';
import clsx from 'clsx';

export const Layout = ({
  children,
  fullWidth
}: {
  children: React.ReactNode;
  fullWidth?: boolean;
}) => {
  return (
    <div className={styles.pageContainer}>
      <TopBar />
      <Header />
      {/*<Navbar />*/}
      <div className={clsx(styles.pageContent, { [styles.fullWidth]: fullWidth })}>{children}</div>
    </div>
  );
};

const TopBar = () => {
  const { data: user } = useGetUserProfileQuery(undefined);

  return (
    <div className={styles.topBar}>
      <div className={styles.topBarInner}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <a href="https://discord.gg/TWasd4rrph" className={styles.discordLink} style={{}}>
            <img src="/discord-mark-white.svg" alt="Discord Logo" height={20} /> Join our Discord
          </a>
        </div>

        {/*{user ? (*/}
        {/*  <Link to="dashboard">*/}
        {/*    <button className={styles.loginButton}>AGIS</button>*/}
        {/*  </Link>*/}
        {/*) : (*/}
        {/*  <a href="/login">*/}
        {/*    <button className={styles.loginButton}>Login</button>*/}
        {/*  </a>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div className={styles.header}>
      <Link to="/">
        <img src="/assassins-greed-logo.png" alt="Assassin's Greed" />
      </Link>
    </div>
  );
};

// const Navbar = () => {
//   return (
//     <div className={styles.navbar}>
//       <div className={styles.navbarInner}>
//         <a href="#home">Home</a>
//         <a href="#home">FAQ</a>
//         <a href="#home">Guides</a>
//         <a href="#home">Builds</a>
//         <a href="#home">Member Roles</a>
//         <a href="#home">Donation Rankings</a>
//       </div>
//     </div>
//   );
// };
