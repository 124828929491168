import { usePerms, useRole } from '../../../../hooks/usePerms';
import { Menu } from 'react-admin';
import { hasPermission, oneOf, Permissions } from '../../../../../shared/Permissions';
import SportsEsports from '@mui/icons-material/SportsEsports';
import Settings from '@mui/icons-material/Settings';
import Lock from '@mui/icons-material/Lock';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Event from '@mui/icons-material/Event';
import ViewList from '@mui/icons-material/ViewList';
import * as React from 'react';
import styles from './Menu.module.less';
import { useStore } from 'ra-core';
import { Box, Stack } from '@mui/material';

export const MyMenu = () => {
  const permissions = usePerms();
  const [isSidebarOpen] = useStore<boolean>('sidebar.open');

  return (
    <Menu className={isSidebarOpen ? '' : 'menu-open'}>
      <Menu.DashboardItem />

      {hasPermission(permissions, Permissions.VIEW_GUILD_EVENTS) && <Heading>Events</Heading>}
      {hasPermission(permissions, Permissions.VIEW_GUILD_EVENTS) && (
        <Menu.Item
          to="/dashboard/events"
          primaryText="Event Calendar"
          leftIcon={<CalendarMonth />}
        />
      )}
      {hasPermission(permissions, Permissions.CREATE_GUILD_EVENT) && (
        <Menu.Item to="/dashboard/create-event" primaryText="Create Event" leftIcon={<Event />} />
      )}

      {hasPermission(permissions, Permissions.VIEW_PUBLISHED_COMPS) && <Heading>Builds</Heading>}
      {hasPermission(permissions, Permissions.VIEW_PUBLISHED_COMPS) && (
        <Menu.Item to="/dashboard/builds" primaryText="Builds" leftIcon={<ViewList />} />
      )}

      {hasPermission(permissions, Permissions.VIEW_ACTIVITIES) && <Heading>Activities</Heading>}
      {hasPermission(permissions, Permissions.SET_OWN_ACTIVITY) && (
        <Menu.Item
          to="/dashboard/guild-activities-settings"
          primaryText="Activity Settings"
          leftIcon={<Settings />}
        />
      )}
      {hasPermission(permissions, Permissions.VIEW_ACTIVITIES) && (
        <Menu.Item
          to="/dashboard/guild-activities"
          primaryText="Guild Activites"
          leftIcon={<SportsEsports />}
        />
      )}

      {hasPermission(
        permissions,
        oneOf([Permissions.VIEW_MEMBERS, Permissions.CHANGE_SETTINGS])
      ) && <Heading>Manage</Heading>}
      {hasPermission(permissions, Permissions.VIEW_MEMBERS) && <Menu.ResourceItem name="members" />}
      {hasPermission(permissions, Permissions.CHANGE_SETTINGS) && (
        <Menu.Item to="/dashboard/settings" primaryText="Settings" leftIcon={<Settings />} />
      )}
      {hasPermission(permissions, Permissions.MANAGE_PERMISSIONS) && (
        <Menu.Item
          to="/dashboard/manage-permissions"
          primaryText="Permissions"
          leftIcon={<Lock />}
        />
      )}

      {hasPermission(permissions, oneOf([Permissions.RUN_ADMIN_FUNCTIONS])) && (
        <Heading>Admin</Heading>
      )}
      {hasPermission(permissions, Permissions.RUN_ADMIN_FUNCTIONS) && (
        <Menu.Item to="/dashboard/admin" primaryText="Admin" leftIcon={<SportsEsports />} />
      )}
    </Menu>
  );
};

const Heading = (props: { children: React.ReactNode }) => {
  const [isSidebarOpen] = useStore<boolean>('sidebar.open');
  if (!isSidebarOpen) {
    return (
      <Box
        sx={{
          background: 'rgb(80,80,80)',
          height: '2px',
          mt: '5px',
          mb: '5px',
          opacity: 0.8
        }}
      />
    );
  }

  return (
    <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 0, ml: 1 }} alignItems="center">
      <h3 className={styles.heading}>{props.children}</h3>
      <Box
        sx={{
          background: 'linear-gradient(to right, rgba(172, 15, 15, 1), rgba(133, 10, 10, 0.7))',
          height: '2px',
          mt: '5px',
          mb: '5px',
          ml: '5px',
          flexGrow: 2,
          opacity: 0.8
        }}
      />
    </Stack>
  );
};
