import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Home } from './pages/home/Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SeasonRewards } from './pages/season-rewards/SeasonRewards';
import { MembersArea } from './pages/dashboard/MembersArea';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: 'season-rewards',
    element: <SeasonRewards />
  },
  {
    path: 'dashboard/*',
    element: <MembersArea />
  }
]);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={darkTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
