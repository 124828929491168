import { Box, Checkbox, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './ActivitySettings.module.less';
import Check from '@mui/icons-material/Check';
import { RouterOutputs, trpc } from '../../../api/trpc';
import { Loader } from '../../../../../components/loader/Loader';
import { useDebouncedCallback } from 'use-debounce';
import { produce } from 'immer';

export const ActivitySettings = () => {
  const { data: playTimes, isPending, isError } = trpc.activities.getPlayerPlayTimes.useQuery();
  const setPlayTimes = trpc.activities.setPlayerPlayTimes.useMutation();

  const debouncedSetPlayTimes = useDebouncedCallback(setPlayTimes.mutate, 1000);

  if (isPending) {
    return <Loader />;
  }

  if (isError) {
    return <div>Error loading play times. Please try again later</div>;
  }

  return (
    <Box sx={{ p: { xs: 0, sm: 2 }, containerType: 'inline-size' }}>
      <Paper sx={{ p: 2 }}>
        <h1>Activity Settings</h1>
        <Typography variant="body2" gutterBottom>
          Updating your Activity Settings will let our Content Creators and other members know what
          type of content you enjoy playing and what times you're most likely to be available.
        </Typography>

        <Typography variant="body2">
          This helps our Content creators schedule content players will enjoy, and estimate what
          size party they are likely to get.
        </Typography>
      </Paper>

      <Box className={styles.settingsContainer} sx={{ p: { xs: 1, sm: 2 } }}>
        <Box>
          <h1>Play Times</h1>
          <Typography variant="body2">
            Select which days/times you are active most often. It doesn't have to be perfect. Click
            and drag to select multiple hours at once.
          </Typography>
        </Box>
        <PlayTimeSettings
          currentTimes={playTimes || []}
          setPlayTimes={newTimes => {
            debouncedSetPlayTimes(newTimes);
          }}
        />

        <Box>
          <h1>Activities You Enjoy</h1>
          <Typography variant="body2" gutterBottom>
            Select which activities you enjoy playing and would like our CCs to create events for.
          </Typography>
        </Box>
        <ActivitiesSettings />
      </Box>
    </Box>
  );
};

const ActivitiesSettings = () => {
  const utils = trpc.useUtils();
  const { data: activities, isPending, isError } = trpc.activities.getActivities.useQuery();
  const { data: playerActivities } = trpc.activities.getPlayerActivities.useQuery();
  const setPlayerActivity = trpc.activities.setPlayerActivity.useMutation({
    onSuccess: () => {
      utils.activities.getPlayerActivities.invalidate();
    },
    onMutate: async ({ activity_id, is_non_lethal, is_lethal }) => {
      utils.activities.getPlayerActivities.setData(undefined, prev => {
        return produce(prev, draft => {
          const activity = draft?.find(activity => activity.activity_id === activity_id);
          if (activity) {
            activity.is_non_lethal =
              is_non_lethal === undefined ? activity.is_non_lethal : is_non_lethal;
            activity.is_lethal = is_lethal === undefined ? activity.is_lethal : is_lethal;
          }
        });
      });
    }
  });

  const playerActivitiesMap = new Map(
    playerActivities?.map(activity => [activity.activity_id, activity]) || []
  );

  if (isPending) {
    return <Loader />;
  }

  if (isError || !activities) {
    return <div>Error loading activities list. Please try again later.</div>;
  }

  return (
    <Box className={styles.activitiesGrid}>
      <div></div>
      <div className={styles.checkboxColumn}>Non-Lethal</div>
      <div className={styles.checkboxColumn}>Lethal</div>
      {activities.map(activity => (
        <Box key={activity.id} className={styles.activityRow}>
          <div>{activity.name}</div>
          <div className={styles.checkboxColumn}>
            {activity.is_non_lethal ? (
              <Checkbox
                id={`non-lethal-${activity.name}`}
                checked={playerActivitiesMap.get(activity.id)?.is_non_lethal || false}
                onChange={e => {
                  setPlayerActivity.mutate({
                    activity_id: activity.id,
                    is_non_lethal: e.target.checked
                  });
                }}
              />
            ) : (
              <Typography variant="body2" sx={{ opacity: '0.5', fontSize: '0.8em' }}>
                x
              </Typography>
            )}
          </div>
          <div className={styles.checkboxColumn}>
            {activity.is_lethal ? (
              <Checkbox
                id={`lethal-${activity.name}`}
                checked={playerActivitiesMap.get(activity.id)?.is_lethal || false}
                onChange={e => {
                  setPlayerActivity.mutate({
                    activity_id: activity.id,
                    is_lethal: e.target.checked
                  });
                }}
              />
            ) : (
              <Typography variant="body2" sx={{ opacity: '0.5', fontSize: '0.8em' }}>
                x
              </Typography>
            )}
          </div>
        </Box>
      ))}
    </Box>
  );
};

const PlayTimeSettings = ({
  currentTimes,
  setPlayTimes
}: {
  currentTimes: NonNullable<RouterOutputs['activities']['getPlayerPlayTimes']>;
  setPlayTimes: (
    times: {
      day_of_week: number;
      time: number;
    }[]
  ) => void;
}) => {
  const utcHours = Array.from({ length: 24 }).map((_, i) => i);
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  const dayIndexes = [1, 2, 3, 4, 5, 6, 0];

  const [selectedCells, setSelectedCells] = useState(
    new Set(currentTimes.map(time => `${time.day_of_week}-${time.time}`))
  );

  const [isDragging, setIsDragging] = useState(false);
  const [isEnabling, setIsEnabling] = useState(false);

  const handleDragStart = (day: number, hour: number) => {
    const key = `${day}-${hour}`;
    const isSelected = selectedCells.has(key);

    setIsDragging(true);
    setIsEnabling(!isSelected);
    setSelectedCells(prevState => {
      const newState = new Set(prevState);
      if (isSelected) {
        newState.delete(key);
      } else {
        newState.add(key);
      }
      return newState;
    });
  };

  const handleDrag = (day: number, hour: number) => {
    if (isDragging) {
      const key = `${day}-${hour}`;
      setSelectedCells(prevState => {
        const newState = new Set(prevState);
        if (isEnabling) {
          newState.add(key);
        } else {
          newState.delete(key);
        }
        return newState;
      });
    }
  };

  const handleDragEnd = () => {
    if (isDragging) {
      setIsDragging(false);
      setPlayTimes(
        Array.from(selectedCells).map(value => ({
          day_of_week: parseInt(value.split('-')[0], 10),
          time: parseInt(value.split('-')[1], 10)
        }))
      );
    }
  };

  return (
    <Box className={styles.playTimeSettings}>
      <Paper sx={{ width: 'fit-content', p: 1 }}>
        <table
          onMouseLeave={handleDragEnd}
          onMouseUp={handleDragEnd}
          className={styles.playTimesTable}
        >
          <thead>
            <tr className={styles.utcRow}>
              <th className={styles.dayCell}>UTC:</th>
              {utcHours.map(time => (
                <th key={time} className={clsx(styles.timeCell, styles.utcRow)}>
                  {time}
                </th>
              ))}
            </tr>
            <tr className={styles.localTimeRow}>
              <th className={styles.dayCell}>Local:</th>
              {utcHours.map(utcHour => {
                const local = convertUTCHourToLocal(utcHour);
                return (
                  <th
                    className={clsx(styles.timeCell, styles.localTime, styles.localTimeRow)}
                    key={local.hour + local.ampm}
                  >
                    <span>{local.hour}</span>
                    <small>{local.ampm}</small>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {dayIndexes.map(day => (
              <tr key={day} className={styles.dayRow}>
                <td className={styles.dayCell}>{days[day]}</td>
                {utcHours.map(hour => (
                  <td
                    key={hour}
                    className={clsx(styles.timeCell, styles.dayTimeCell)}
                    onMouseDown={e => {
                      e.preventDefault();
                      handleDragStart(day, hour);
                    }}
                    onMouseEnter={() => handleDrag(day, hour)}
                  >
                    {selectedCells.has(`${day}-${hour}`) && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Check />
                      </div>
                    )}
                    <input type="checkbox" checked={selectedCells.has(`${day}-${hour}`)} readOnly />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Paper>
    </Box>
  );
};

function convertUTCHourToLocal(hourUTC: number) {
  // Create a Date object for today's date at the UTC hour specified
  const now = new Date();
  const today = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), hourUTC)
  );

  // Convert the UTC date object to the local time zone
  const localHour = today.getHours();

  // Determine AM or PM
  const ampm = localHour >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  const hour12 = localHour % 12 || 12; // Convert 0 to 12 for 12 AM

  return {
    hour: hour12,
    ampm: ampm
  };
}
