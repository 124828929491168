import { ContentBox } from '../../components/ContentBox';
import { Button, CircularProgress, Stack } from '@mui/material';
import { trpc } from '../../api/trpc';
import { green } from '@mui/material/colors';

export const AdminPage = () => {
  const importUsers = trpc.admin.importAllUsers.useMutation();
  const importUserActivity = trpc.admin.importUserActivity.useMutation();
  const customAction = trpc.admin.customAction.useMutation();

  return (
    <ContentBox>
      <Stack gap={2}>
        <Stack flexDirection="row" gap={1} alignItems="center">
          <Button
            variant="contained"
            onClick={() => importUsers.mutate()}
            disabled={importUsers.isPending}
          >
            Import Users
          </Button>
          {importUsers.error && <div>{importUsers.error.message}</div>}
          {importUsers.isPending && <CircularProgress size={24} sx={{ color: green[500] }} />}
          {importUsers.isSuccess && <div>Success!</div>}
        </Stack>

        <Stack flexDirection="row" gap={1} alignItems="center">
          <Button
            variant="contained"
            onClick={() => importUserActivity.mutate()}
            disabled={importUserActivity.isPending}
          >
            Import User Activity
          </Button>
          {importUserActivity.error && <div>{importUserActivity.error.message}</div>}
          {importUserActivity.isPending && (
            <CircularProgress size={24} sx={{ color: green[500] }} />
          )}
          {importUserActivity.isSuccess && <div>Success!</div>}
        </Stack>

        <Stack flexDirection="row" gap={1} alignItems="center">
          <Button
            variant="contained"
            onClick={async () => {
              const response = await customAction.mutateAsync();
              console.log(response);
            }}
            disabled={customAction.isPending}
          >
            Do Custom Action
          </Button>
          {customAction.error && <div>{customAction.error.message}</div>}
          {customAction.isPending && <CircularProgress size={24} sx={{ color: green[500] }} />}
          {customAction.isSuccess && <div>Success!</div>}
        </Stack>
      </Stack>
    </ContentBox>
  );
};
