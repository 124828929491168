export enum Permissions {
  // General
  ACCESS_DASHBOARD = 'ACCESS_DASHBOARD',

  // Events
  VIEW_GUILD_EVENTS = 'VIEW_GUILD_EVENTS',
  CREATE_GUILD_EVENT = 'CREATE_GUILD_EVENT',
  CREATE_ALLIANCE_EVENT = 'CREATE_ALLIANCE_EVENT',
  EDIT_OWN_EVENT = 'EDIT_OWN_EVENT',
  EDIT_ANY_EVENT = 'EDIT_ANY_EVENT',
  DELETE_OWN_EVENT = 'DELETE_OWN_EVENT',
  DELETE_ANY_EVENT = 'DELETE_ANY_EVENT',
  RUN_OWN_EVENT = 'RUN_OWN_EVENT',
  RUN_ANY_EVENT = 'RUN_ANY_EVENT',
  REGISTER_GUILD_EVENT = 'REGISTER_GUILD_EVENT',
  VIEW_ALLIANCE_EVENTS = 'VIEW_ALLIANCE_EVENTS',
  REGISTER_ALLIANCE_EVENT = 'REGISTER_ALLIANCE_EVENT',

  // Comps
  VIEW_PUBLISHED_COMPS = 'VIEW_PUBLISHED_COMPS',
  VIEW_OWN_COMPS = 'VIEW_OWN_COMPS',
  VIEW_ALL_COMPS = 'VIEW_ANY_COMPS',
  CREATE_COMP = 'CREATE_COMP',
  EDIT_OWN_COMP = 'EDIT_OWN_COMP',
  EDIT_ANY_COMP = 'EDIT_ANY_COMP',
  DELETE_OWN_COMP = 'DELETE_OWN_COMP',
  DELETE_ANY_COMP = 'DELETE_ANY_COMP',

  // Activities
  SET_ANY_ACTIVITY = 'SET_ANY_ACTIVITY',
  SET_OWN_ACTIVITY = 'SET_OWN_ACTIVITY',
  VIEW_ACTIVITIES = 'VIEW_ACTIVITIES',

  // Guild Management
  VIEW_MEMBERS = 'VIEW_MEMBERS',

  // Admin
  CHANGE_SETTINGS = 'CHANGE_SETTINGS',
  FETCH_ALL_VOICE_CHANNELS = 'FETCH_ALL_VOICE_CHANNELS',
  MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',

  // Super Admin
  RUN_ADMIN_FUNCTIONS = 'RUN_ADMIN_FUNCTIONS'
}

export const SUPER_ADMIN_PERMISSIONS = [Permissions.RUN_ADMIN_FUNCTIONS];

export type OneOf = { oneOf: string[] };
export const oneOf = (permissions: string[]): OneOf => ({
  oneOf: permissions
});

export type AllOf = { allOf: string[] };
export const allOf = (permissions: string[]): AllOf => ({
  allOf: permissions
});

export const hasPermission = (userPermissions: string[], permission: string | OneOf | AllOf) => {
  if (typeof permission === 'string') {
    return userPermissions.includes(permission);
  }

  if ('oneOf' in permission) {
    return permission.oneOf.some(p => userPermissions.includes(p));
  }

  return permission.allOf.every(p => userPermissions.includes(p));
};

export const hasEntityPermissions = (
  userPermissions: string[],
  ownPermission: string,
  anyPermission: string,
  isOwn: boolean
) => {
  if (userPermissions.includes(anyPermission)) {
    return true;
  }

  if (isOwn) {
    return userPermissions.includes(ownPermission);
  }

  return false;
};
