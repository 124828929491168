import { useController } from 'react-hook-form';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  InsertThematicBreak,
  CreateLink,
  ListsToggle,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  MDXEditor,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  markdownShortcutPlugin
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import styles from './MarkdownEditor.module.less';
import React from 'react';
import clsx from 'clsx';

export const MarkdownEditor = ({
  name,
  defaultValue,
  label,
  className
}: {
  name: string;
  defaultValue: string;
  label?: React.ReactNode;
  className?: string;
}) => {
  const inputController = useController({ name, defaultValue });
  const { value, onChange, ...field } = inputController.field;

  /* TODO: Fix underline somehow */
  return (
    <MDXEditor
      className={clsx('light-theme', styles.markdownEditor, className)}
      markdown={defaultValue}
      onChange={onChange}
      {...field}
      plugins={[
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        thematicBreakPlugin(),
        markdownShortcutPlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <p className={styles.label}>{label}</p>
              <BlockTypeSelect />
              <BoldItalicUnderlineToggles />
              <CreateLink />
              <InsertThematicBreak />
              <ListsToggle />
            </>
          )
        })
      ]}
    />
  );
};
