import React, { useState } from 'react';
import styles from './ViewComp.module.less';
import { Title } from 'react-admin';
import { ContentBox } from '../../../components/ContentBox';
import { useParams } from 'react-router-dom';
import { useGetCompQuery, useGetRolesQuery } from '../../../../../store/api/Comps';
import { Loader } from '../../../../../components/loader/Loader';
import { Box, Button, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import {
  CompBuild,
  CompBuildItem,
  CompRole,
  SlotType
} from '../../../../../../shared/types/api/Comps';
import { DiscordEmoji } from '../../../../../components/discord-emoji/DiscordEmoji';
import clsx from 'clsx';
import { Tooltip } from 'react-tooltip';
import { TitleBox } from '../../../components/title-box/TitleBox';

const CompContext = React.createContext<{
  comp_id: number | null;
  selectedRoleId: number | null;
  selectedBuildId: number | null;
  isCompFetching: boolean;
}>({
  comp_id: null,
  selectedRoleId: null,
  selectedBuildId: null,
  isCompFetching: false
});

export const ViewComp = () => {
  const { id } = useParams() as { id: string };
  const { data: comp, isFetching: isCompFetching } = useGetCompQuery({ id });
  const { data: roles } = useGetRolesQuery(undefined);
  const [selectedRoleIdState, setSelectedRoleId] = useState<number | null>(null);
  const [selectedBuildId, setSelectedBuildId] = useState<number | null>(null);

  if (!comp || !roles) {
    return <Loader />;
  }

  const selectedRoleId = selectedRoleIdState || comp?.roles?.[0]?.id;
  const selectedRoleData = comp.roles?.find(role => role?.id === selectedRoleId);
  const builds = comp.builds || [];
  const selectedBuild = builds.find(build => build?.id === selectedBuildId);

  return (
    <CompContext.Provider
      value={{
        comp_id: comp.id,
        selectedRoleId,
        selectedBuildId,
        isCompFetching
      }}
    >
      <ContentBox padding={1}>
        <Title title={comp.name} />
        <TitleBox>
          <h1>{comp.name}</h1>
        </TitleBox>
        <div
          className={clsx(styles.container, {
            [styles.viewList]: selectedBuildId === null,
            [styles.viewBuild]: selectedBuildId !== null
          })}
        >
          <RoleListPane
            currentRoles={comp.roles}
            selectedRole={selectedRoleId}
            onRoleSelected={roleId => {
              setSelectedRoleId(roleId);
              setSelectedBuildId(null);
            }}
            isBuildSelected={selectedBuildId !== null}
          />{' '}
          {selectedRoleData && (
            <BuildListPane
              builds={builds}
              onBuildSelected={buildId => {
                setSelectedBuildId(buildId);
              }}
              selectedBuildId={selectedBuildId}
              selectedRole={selectedRoleData}
            />
          )}
          {selectedBuild && <BuildViewPane build={selectedBuild} />}
          {selectedBuild && <SlotItems items={selectedBuild.items} />}
        </div>
      </ContentBox>
    </CompContext.Provider>
  );
};

const RoleListPane = ({
  currentRoles,
  selectedRole,
  onRoleSelected,
  isBuildSelected
}: {
  currentRoles: CompRole[] | null;
  selectedRole: number | null;
  onRoleSelected: (role: number | null) => void;
  isBuildSelected: boolean;
}) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.up('xs'));

  return (
    <Stack
      sx={{ height: '100%', padding: 2, width: { xs: '100%', sm: isBuildSelected ? 100 : '100%' } }}
      component={Paper}
      spacing={2}
      className={styles.roleListPane}
    >
      {[...(currentRoles || [])]
        ?.sort((roleA, roleB) => (roleA?.order || 99999) - (roleB?.order || 99999))
        .map(role => {
          if (!role || !role?.type) {
            return null;
          }

          const isSelected = selectedRole === role.id;

          return (
            <Button
              variant={isSelected ? 'contained' : 'outlined'}
              key={role.id}
              startIcon={<DiscordEmoji id={role.type.icon || '1135300543024205894'} size={24} />}
              endIcon={
                xs ? (
                  isSelected ? (
                    <ArrowRight />
                  ) : (
                    <Box sx={{ width: 20, height: 20 }} />
                  )
                ) : isSelected ? (
                  <ArrowRight />
                ) : isBuildSelected ? null : (
                  <Box sx={{ width: 20, height: 20 }} />
                )
              }
              sx={{
                justifyContent: {
                  xs: 'flex-start',
                  sm: isBuildSelected ? 'center' : 'flex-start'
                },
                '& .MuiButton-startIcon': {
                  marginLeft: { xs: '-4px', sm: isBuildSelected ? 0 : '-4px' },
                  marginRight: { xs: '8px', sm: isBuildSelected ? 0 : '8px' }
                }
              }}
              onClick={() => onRoleSelected(role.id)}
            >
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    sm: isBuildSelected ? 'none' : 'block'
                  }
                }}
              >
                {role.type.name}
              </Box>
            </Button>
          );
        })}
    </Stack>
  );
};

const BuildListPane = ({
  selectedRole,
  selectedBuildId,
  onBuildSelected,
  builds
}: {
  selectedRole: Exclude<CompRole, null | undefined>;
  selectedBuildId: number | null;
  onBuildSelected: (build: number | null) => void;
  builds: CompBuild[];
}) => {
  const buildsForRole = builds.filter(build => build?.role_id === selectedRole.role_type_id);
  return (
    <Stack
      spacing={2}
      component={Paper}
      sx={{ padding: 2 }}
      alignItems={{ xs: 'center', sm: selectedBuildId === null ? 'flex-start' : 'center' }}
      className={styles.buildListPane}
    >
      <h2 style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <DiscordEmoji id={selectedRole.type?.icon || ''} onClick={() => onBuildSelected(null)} />
        <Box
          sx={{
            display: { xs: 'inline', sm: selectedBuildId ? 'none' : 'inline' }
          }}
        >
          {selectedRole.type?.name} Builds
        </Box>
      </h2>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          rowGap: 1,
          justifyContent: 'center',
          gridTemplateColumns: {
            xs: 'repeat(auto-fill, 105px)',
            sm: selectedBuildId !== null ? '75px' : '1fr'
          }
        }}
      >
        {buildsForRole.map(build => {
          if (!build) return null;

          if (selectedBuildId !== null) {
            return (
              <BuildButton
                key={build.id}
                build={build}
                selected={build.id === selectedBuildId}
                onClick={() => onBuildSelected(build.id)}
              />
            );
          }

          return (
            <BuildRow key={build.id} build={build} onClick={() => onBuildSelected(build.id)} />
          );
        })}
      </Box>
    </Stack>
  );
};

export const BuildViewPane = ({ build }: { build: CompBuild }) => {
  if (!build) return null;
  const items = build.items || [];

  const slots = [
    [SlotType.Weapon, styles.weaponSlot],
    [SlotType.Offhand, styles.offhandSlot],
    [SlotType.Head, styles.headSlot],
    [SlotType.Chest, styles.armorSlot],
    [SlotType.Feet, styles.legsSlot],
    [SlotType.Cape, styles.capeSlot],
    [SlotType.Food, styles.foodSlot],
    [SlotType.Potion, styles.potionSlot]
  ] as const;

  return (
    <div className={styles.buildView}>
      <div className={styles.gear}>
        {slots.map(([slotType, className]) => (
          <Slot
            key={slotType}
            items={items.filter(item => {
              if (
                slotType === SlotType.Offhand &&
                item?.item?.slot === SlotType.Weapon &&
                item?.item?.two_handed
              ) {
                return true;
              }

              return item?.item?.slot === slotType;
            })}
            slotType={slotType}
            className={className}
          />
        ))}
      </div>
    </div>
  );
};

const BuildButton = ({
  build,
  onClick,
  selected
}: {
  build: Exclude<CompBuild, null>;
  onClick: () => void;
  selected?: boolean;
}) => {
  const items = build.items || [];

  return (
    <div
      className={styles.buildButton}
      onClick={onClick}
      style={{
        borderRadius: '8px',
        backgroundColor: selected ? 'lightblue' : 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Slot
        items={items.filter(item => item?.item?.slot === SlotType.Weapon)}
        slotType={SlotType.Weapon}
        context="buildList"
      />
    </div>
  );
};

export const BuildRow = ({
  build,
  onClick
}: {
  build: Exclude<CompBuild, null>;
  onClick: () => void;
}) => {
  const items = build.items || [];

  const slots = [
    SlotType.Weapon,
    SlotType.Offhand,
    SlotType.Head,
    SlotType.Chest,
    SlotType.Feet,
    SlotType.Cape,
    SlotType.Food,
    SlotType.Potion
  ] as const;

  return (
    <Stack
      spacing={1}
      direction="row"
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        border: '1px solid transparent',
        borderRadius: '10px',
        '&:hover': { border: '1px solid white' }
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {slots.map(slotType => (
          <Slot
            key={slotType}
            items={items.filter(item => {
              if (
                slotType === SlotType.Offhand &&
                item?.item?.slot === SlotType.Weapon &&
                item?.item?.two_handed
              ) {
                return true;
              }

              return item?.item?.slot === slotType;
            })}
            slotType={slotType}
            context="buildList"
          />
        ))}
      </Box>
    </Stack>
  );
};

const Slot = ({
  items,
  slotType,
  context = 'build',
  className
}: {
  items: CompBuildItem[];
  slotType: SlotType;
  context?: 'buildList' | 'build' | 'deleteList';
  className?: string;
}) => {
  const isBuildList = context === 'buildList';
  const isDeleteList = context === 'deleteList';
  const is2Handed = slotType === SlotType.Offhand && items.some(item => item?.item?.two_handed);

  return (
    <div
      className={clsx(styles.slot, className, styles[slotType], {
        [styles.deleteListSlot]: isDeleteList,
        [styles.buildListSlot]: isBuildList
      })}
    >
      <ItemImageContainer count={items.length} className={clsx({ [styles.twoHanded]: is2Handed })}>
        {items.length > 0 ? (
          items.map(item => {
            if (!item) return null;
            return (
              <ItemImage
                key={item.item?.id}
                item={{
                  id: item.item?.id || 0,
                  icon: item.item?.icon || null,
                  name: item.item?.name || null
                }}
                context={context + slotType}
              />
            );
          })
        ) : isBuildList || isDeleteList ? (
          <Placeholder />
        ) : null}
      </ItemImageContainer>
    </div>
  );
};

const Placeholder = () => {
  return <div className={styles.placeholder} />;
};

export const ItemImageContainer = ({
  children,
  count,
  className
}: {
  children: React.ReactNode;
  count: number;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        styles.itemContainer,
        {
          [styles.items2]: count === 2,
          [styles.items3plus]: count >= 3
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export const ItemImage = ({
  item,
  className,
  context
}: {
  item: {
    id: number;
    icon?: string | null;
    name?: string | null;
  } | null;
  className?: string;
  context: string;
}) => (
  <div className={clsx(styles.item, className)}>
    <img
      className={styles.itemImage}
      src={`https://iqwgqnbvoejzaqceivdm.supabase.co/storage/v1/object/public/icons/${item?.icon}`}
      alt={item?.name || ''}
      data-tooltip-id={context + item?.id?.toString()}
      data-tooltip-content={item?.name}
    />
    <Tooltip id={context + item?.id?.toString()} variant="light" style={{ zIndex: 1000 }} />
  </div>
);

const SlotItems = ({ items }: { items: CompBuildItem[] }) => {
  return (
    <Stack spacing={2} alignItems="center">
      {items.map(item => (
        <div className={styles.slotItem} key={item.id}>
          <Stack direction="row" spacing={1} alignItems="center">
            <ItemImage
              item={{ id: item.id, icon: item.item?.icon, name: item.item?.name }}
              className={styles.slotInfoIcon}
              context="spellImage"
            />
            <h3>{item.item?.name}</h3>
          </Stack>

          <SelectedSpells item={item} />
        </div>
      ))}
    </Stack>
  );
};

const SelectedSpells = ({ item }: { item: CompBuildItem }) => {
  const spells = item.selected_spells || [];
  const slotOrder = ['q', 'w', 'e', 'd', 'r', 'f', 'passive 1', 'passive 2'];

  return (
    <Stack direction="row">
      {[...spells]
        .sort(
          (a, b) => slotOrder.indexOf(a.spell?.slot || '') - slotOrder.indexOf(b.spell?.slot || '')
        )
        .map(spell => {
          return (
            <React.Fragment key={spell.spell?.id}>
              <Stack alignItems="center">
                <img
                  src={`https://iqwgqnbvoejzaqceivdm.supabase.co/storage/v1/object/public/icons/${spell.spell?.icon}`}
                  alt={spell.spell?.name}
                  className={clsx(styles.spellIcon, {
                    [styles.selectedSpell]: true
                  })}
                  data-tooltip-id={spell?.spell?.id?.toString()}
                  data-tooltip-content={spell?.spell?.name}
                />
                <div style={{ marginLeft: '-2px' }}>
                  {spell.spell?.slot.substring(0, 1).toUpperCase()}
                </div>
              </Stack>
              <Tooltip id={spell?.spell?.id?.toString()} variant="light" style={{ zIndex: 1000 }} />
            </React.Fragment>
          );
        })}
    </Stack>
  );
};
