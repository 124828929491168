import clsx from 'clsx';
import React from 'react';
import styles from './PlaytimesChart.module.less';
import { trpc } from '../../pages/dashboard/api/trpc';

const utcHours = Array.from({ length: 24 }, (_, i) => i);

export const PlaytimesChart = () => {
  const { data } = trpc.activities.getActiveTimes.useQuery();

  return (
    <div className={styles.playTimesTableContainer}>
      <div className={styles.playTimesKey}>
        <div className={styles.keyLabel}>
          <div className={styles.timeCell} style={{ width: 10, height: 10 }} data-level={1} />
          <span>1-4</span>
        </div>
        <div className={styles.keyLabel}>
          <div className={styles.timeCell} style={{ width: 10, height: 10 }} data-level={2} />
          <span>5-9</span>
        </div>
        <div className={styles.keyLabel}>
          <div className={styles.timeCell} style={{ width: 10, height: 10 }} data-level={3} />
          <span>10-14</span>
        </div>
        <div className={styles.keyLabel}>
          <div className={styles.timeCell} style={{ width: 10, height: 10 }} data-level={4} />
          <span>15+</span>
        </div>
      </div>
      <table className={styles.playTimesTable}>
        <thead>
          <tr className={styles.utcRow}>
            <th className={styles.dayCell}>UTC:</th>
            {utcHours.map(time => (
              <th className={styles.timeCell} key={time}>
                {time}
              </th>
            ))}
          </tr>
          <tr className={styles.localTimeRow}>
            <th className={styles.dayCell}>Local:</th>
            {utcHours.map(utcHour => {
              const local = convertUTCHourToLocal(utcHour);
              return (
                <th
                  className={clsx(styles.timeCell, styles.localTime)}
                  key={local.hour + local.ampm}
                >
                  <span>{local.hour}</span>
                  <small>{local.ampm}</small>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5, 6, 0].map(day => (
            <Day day={day} data={data} key={day} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Day = ({
  day,
  data
}: {
  day: number;
  data:
    | {
        day_of_week: number | null;
        time: string | null;
        instance_count: number | null;
      }[]
    | undefined
    | null;
}) => {
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  const timeCounts = data?.filter(d => d.day_of_week === day) || [];

  return (
    <tr className={styles.dayRow}>
      <th className={styles.dayCell}>{dayNames[day]}</th>
      {utcHours.map(time => {
        if (timeCounts.length === 0)
          return (
            <td className={styles.timeCell} data-level={0} key={time}>
              0
            </td>
          );

        const count =
          timeCounts.find(row => parseInt(row.time?.substr(0, 2) || '-1', 10) === time)
            ?.instance_count || 0;

        let level = 0;
        if (count >= 1) {
          level = 1;
        }

        if (count >= 5) {
          level = 2;
        }

        if (count >= 10) {
          level = 3;
        }

        if (count >= 15) {
          level = 4;
        }

        return (
          <td className={styles.timeCell} data-level={level} key={time}>
            {count}
          </td>
        );
      })}
    </tr>
  );
};

function convertUTCHourToLocal(hourUTC: number) {
  // Create a Date object for today's date at the UTC hour specified
  const now = new Date();
  const today = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), hourUTC)
  );

  // Convert the UTC date object to the local time zone
  const localHour = today.getHours();

  // Determine AM or PM
  const ampm = localHour >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  const hour12 = localHour % 12 || 12; // Convert 0 to 12 for 12 AM

  return {
    hour: hour12,
    ampm: ampm
  };
}
