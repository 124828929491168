import { ContentBox } from '../../components/ContentBox';
import { Title } from 'react-admin';
import { Alert, Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import styles from './Dashboard.module.less';
import { Link } from 'react-router-dom';

export const Dashboard = () => {
  return (
    <ContentBox>
      <Title title="AGIS" />
      <Alert
        severity="warning"
        sx={{
          gap: {
            xs: 1,
            sm: 0
          }
        }}
      >
        This is a beta application. There may be bugs. If you have issues, please reach out to
        LostKaractr.
      </Alert>
      <div className={styles.mainGrid}>
        <Box>
          <Card elevation={3} sx={{ mb: 2 }}>
            <CardContent>
              <h1>Welcome to AGIS!</h1>
              <p>
                The Assassin's Greed Intelligence System (AGIS) is here to help guide you through
                your Albion journey in Assassin's Greed. Using AGIS, you can find information about
                the guild, <Link to="events">upcoming events</Link>,{' '}
                <Link to="builds">comps & builds</Link>, and more. You may also{' '}
                <Link to="guild-activities-settings">
                  set your preferred play times and activities
                </Link>{' '}
                to help inform our Content Creators of what kind of content to run and when to run
                it!
              </p>
              <p style={{ marginTop: '10px' }}>
                AGIS is meant to help AG leadership manage the guild and make decisions based on
                player activity and preferences. The more you interact with AGIS, the better we will
                be at organizing and running content that you care about and enjoy, so please use it
                often!
              </p>
              <p style={{ marginTop: '10px' }}>
                If you have any questions or run into any problems with AGIS, please reach out to
                LostKaractr on Discord.
              </p>
            </CardContent>
          </Card>
          <Alert severity="info">
            <p>
              More panels will appear here as the application is developed further. You may see
              panels in the future for:
            </p>
            <ul>
              <li>Silver Owed to you</li>
              <li>Regears Owed to you</li>
              <li>Your event attendance stats</li>
              <li>Badges and honors</li>
              <li>Kill/Death Stats</li>
              <li>Even more...</li>
            </ul>
          </Alert>
        </Box>
        <Box>
          <Card>
            <CardMedia sx={{ height: 180 }} image="/rect-table.jpg" />
            <CardContent>
              <h2 style={{ marginBottom: '3px' }}>Become a Content Creator</h2>
              <Typography gutterBottom variant="body2" component="div">
                💲💲 Earn up to 1,000,000/day running events! 💲💲
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                Content Creators (CCs) can earn 500,000 silver per hour (up to 1,000,000 per day)
                running events! We need CCs who are mature and experienced to lead our players in
                all sorts of content. If you fit that description, reach out to guild leadership and
                apply today!
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </div>
    </ContentBox>
  );
};
