import { Loader } from '../../../../components/loader/Loader';
import { ContentBox } from '../../components/ContentBox';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Button, Title } from 'react-admin';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import { Link, useNavigate } from 'react-router-dom';
import ViewList from '@mui/icons-material/ViewList';
import { trpc } from '../../api/trpc';
import { RouterOutputs } from '../../../../../server/AppRouter';
import { EventRunningBanner } from '../../components/event-running-banner/EventRunningBanner';
import React from 'react';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(utc);

export const Events = () => {
  const { data: events, isPending, isError } = trpc.events.getEventsList.useQuery();

  if (isPending) {
    return <Loader />;
  }

  if (isError) {
    return <ContentBox padding={0}>Error loading events.</ContentBox>;
  }

  return (
    <ContentBox padding={0}>
      <Title title="Event Calendar" />
      <EventsList events={events} />
    </ContentBox>
  );
};

const EventsList = ({ events }: { events: RouterOutputs['events']['getEventsList'] }) => {
  const today = dayjs().startOf('day');

  const upcomingCurrentEventsGroup = events.filter(event => {
    const eventDate = dayjs(event.start_time).utc(true).local().startOf('day');
    return eventDate.isSameOrAfter(today) && !event.ended_time;
  });

  const pastEventsGroup = events.filter(event => {
    const eventDate = dayjs(event.start_time).utc(true).local().startOf('day');
    return eventDate.isBefore(today) || event.ended_time;
  });

  // Group each collection of events by date
  const upcomingCurrentEvents = Object.entries(
    upcomingCurrentEventsGroup.reduce(
      (acc, event) => {
        const eventDate = dayjs(event.start_time).utc(true).local().startOf('day');
        const key = eventDate.format('YYYY-MM-DD');
        if (!acc[key]) acc[key] = [];
        acc[key].push(event);
        return acc;
      },
      {} as Record<string, RouterOutputs['events']['getEventsList']>
    )
  ).sort(([a], [b]) => (dayjs(a).utc(true).local().isBefore(dayjs(b).utc(true).local()) ? -1 : 1));

  const pastEvents = Object.entries(
    pastEventsGroup.reduce(
      (acc, event) => {
        const eventDate = dayjs(event.start_time).utc(true).local().startOf('day');
        const key = eventDate.format('YYYY-MM-DD');
        if (!acc[key]) acc[key] = [];
        acc[key].push(event);
        return acc;
      },
      {} as Record<string, RouterOutputs['events']['getEventsList']>
    )
  ).sort(([a], [b]) => (dayjs(b).utc(true).local().isBefore(dayjs(a).utc(true).local()) ? -1 : 1));

  return (
    <Box>
      <EventSectionTitle>Upcoming Events</EventSectionTitle>
      <EventSection events={Object.fromEntries(upcomingCurrentEvents)} />

      <EventSectionTitle>Past Events</EventSectionTitle>
      <EventSection events={Object.fromEntries(pastEvents)} />
    </Box>
  );
};

const EventSectionTitle = ({ children }: { children: React.ReactNode }) => (
  <Paper elevation={0}>
    <h2
      style={{
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
        padding: '10px 20px'
      }}
    >
      <CalendarMonth /> {children}
    </h2>
  </Paper>
);

const EventSection = ({
  events
}: {
  events: Record<string, RouterOutputs['events']['getEventsList']>;
}) => (
  <Stack spacing={2} sx={{ marginBottom: 8, marginLeft: 1, marginRight: 1 }}>
    {Object.keys(events).length ? (
      Object.entries(events).map(([date, events]) => (
        <Stack spacing={2} key={date}>
          <Typography
            variant="h6"
            sx={{
              borderBottom: '1px solid rgba(100, 100, 100, 1)',
              padding: 1,
              paddingLeft: 1
            }}
          >
            {dayjs(date).format('dddd, MMMM D')}
            {dayjs(date).isSame(dayjs().startOf('day')) && ' (Today)'}
            {dayjs(date).isSame(dayjs().add(1, 'day').startOf('day')) && ' (Tomorrow)'}
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            sx={{ paddingLeft: 1 }}
            alignItems="flex-start"
          >
            {events
              .sort((a, b) =>
                dayjs(a.start_time)
                  .utc(true)
                  .local()
                  .isBefore(dayjs(b.start_time).utc(true).local())
                  ? -1
                  : 1
              )
              .map(event => (
                <EventCard key={event.id} event={event} />
              ))}
          </Stack>
        </Stack>
      ))
    ) : (
      <Paper sx={{ padding: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>No events.</Paper>
    )}
  </Stack>
);

const EventCard = ({ event }: { event: RouterOutputs['events']['getEventsList'][number] }) => {
  const navigate = useNavigate();
  const { name, start_time, started_time, ended_time, shotcaller, image_url, participants } = event;
  const url = `/dashboard/events/${event.id}`;
  const isPast = !!ended_time;
  const isRunning = started_time && !isPast;

  return (
    <Card sx={{ width: '300px' }}>
      <Stack sx={{ justifyContent: 'space-between', height: '100%', pb: isRunning ? 0 : 1 }}>
        <CardActionArea
          onClick={() => navigate(url)}
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          }}
        >
          <CardMedia
            component="img"
            height={150}
            image={
              image_url ??
              `https://iqwgqnbvoejzaqceivdm.supabase.co/storage/v1/object/public/event_images/og-assassins-greed-logo-discord.png`
            }
            alt={name}
          />
          <CardContent>
            <Stack spacing={1}>
              <Typography variant="h6">{name}</Typography>
              <Typography variant="subtitle2" color="rgb(150,150,150)" component="div">
                {dayjs(start_time).utc(true).local().format('dddd @ LT')}
              </Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
        <Stack spacing="1px">
          <Detail>
            <Typography
              variant="body2"
              color="text.secondary"
              component="span"
              sx={{ display: 'flex', gap: '8px', alignItems: 'center', pl: 1 }}
            >
              <ViewList />{' '}
              {event.comp?.id && event.comp.id !== 1 ? (
                <Link to={`/dashboard/builds/${event.comp.id}`}>{event.comp.name}</Link>
              ) : (
                'Any Builds'
              )}
            </Typography>
          </Detail>
          <Detail>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ pl: 1 }}>
              <Avatar src={shotcaller?.avatar || ''} sx={{ width: 24, height: 24 }} />
              <Typography variant="body2" color="text.secondary" component="span">
                {shotcaller?.albion_name}
              </Typography>
            </Stack>
          </Detail>
        </Stack>
        {!isRunning && (
          <CardActions sx={{ padding: 2 }}>
            {!isPast && (
              <Link to={`/dashboard/events/${event.id}/?register=true`}>
                <Button label="Register" variant="contained" />
              </Link>
            )}
            {isPast && <span>{participants.length} Registered</span>}
          </CardActions>
        )}
        {isRunning && <EventRunningBanner sx={{ mb: 0, borderRadius: 0 }} />}
      </Stack>
    </Card>
  );
};

const Detail = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      padding: 1,
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
      '&:hover': {
        backgroundColor: 'rgba(100, 100, 100, 0.1)'
      }
    }}
  >
    {children}
  </Box>
);
