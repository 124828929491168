import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { rootReducer } from './RootReducer';
import { listenerMiddleware } from './middleware/Listener';
import { apiMiddleware, apiReducerPaths } from './api';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: apiReducerPaths
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
      .concat(...apiMiddleware)
      .prepend(listenerMiddleware.middleware)
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the state itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
