import * as React from 'react';
import { Box, Button } from '@mui/material';

export const LoginPage = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
      }}
    >
      <Box
        sx={{
          color: 'white',
          backgroundColor: 'rgba(0,0,0,0.8)',
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '10px'
        }}
      >
        <h1>Login Required</h1>
        <p style={{ marginBottom: 20 }}>You must be logged in to view this page.</p>
        <a href="/login">
          <Button variant="contained" size="large" color="secondary">
            Login with Discord
          </Button>
        </a>
      </Box>
    </Box>
  );
};
