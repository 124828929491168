import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CompBuildItem, CompList, CompResponse, SlotType } from '../../../shared/types/api/Comps';
import {
  Albion_Item,
  Albion_Item_Category,
  Albion_Item_Subcategory,
  Comp_Role_Type
} from '../../../shared/types/database/database';

export const comps = createApi({
  reducerPath: 'comps',
  tagTypes: ['Comp'],
  baseQuery: fetchBaseQuery({ baseUrl: '/api/comps' }),
  endpoints: builder => ({
    getCompList: builder.query<CompList, undefined>({
      query: () => '/',
      providesTags: data =>
        data
          ? [
              { type: 'Comp', id: 'LIST' },
              ...data.map<{ type: 'Comp'; id: string }>(comp => ({
                type: 'Comp',
                id: comp.id.toString()
              }))
            ]
          : []
    }),

    createComp: builder.mutation({
      query: () => ({
        url: '/create',
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'Comp', id: 'LIST' }]
    }),

    getComp: builder.query<CompResponse, { id: string }>({
      query: ({ id }: { id: string }) => `/${id}`,
      providesTags: data => (data ? [{ type: 'Comp', id: data.id }] : [])
    }),

    updateComp: builder.mutation<void, { id: number; name: string }>({
      query: data => ({
        url: `/${data.id}`,
        method: 'POST',
        body: { name: data.name }
      }),
      invalidatesTags: (_, __, body) => [{ type: 'Comp', id: body.id.toString() }]
    }),

    deleteComp: builder.mutation<void, { id: number }>({
      query: data => ({
        url: '/delete',
        method: 'POST',
        body: data
      }),
      invalidatesTags: (_, __, body) => [{ type: 'Comp', id: body.id.toString() }]
    }),

    getRoles: builder.query<Comp_Role_Type[], undefined>({
      query: () => '/roles'
    }),

    addRoleToComp: builder.mutation<void, { comp_id: number; role_id: number }>({
      query: data => ({
        url: `/${data.comp_id}/add-role`,
        method: 'POST',
        body: {
          role_type_id: data.role_id
        }
      }),
      invalidatesTags: (_, __, data) => [{ type: 'Comp', id: data.comp_id.toString() }]
    }),

    removeRoleFromComp: builder.mutation<void, { comp_id: number; role_type_id: number }>({
      query: data => ({
        url: `/${data.comp_id}/role/${data.role_type_id}/delete`,
        method: 'POST'
      }),
      invalidatesTags: (_, __, data) => [{ type: 'Comp', id: data.comp_id.toString() }]
    }),

    createBuild: builder.mutation<{ id: number }, { comp_id: number; role_id: number }>({
      query: data => ({
        url: `/${data.comp_id}/build/create`,
        method: 'POST',
        body: {
          role_id: data.role_id
        }
      }),
      invalidatesTags: (_, __, data) => [{ type: 'Comp', id: data.comp_id.toString() }]
      // onQueryStarted: async ({ comp_id, role_id }, { dispatch, queryFulfilled }) => {
      //   const { data } = await queryFulfilled;
      //   dispatch(
      //     comps.util.updateQueryData('getComp', { id: comp_id.toString() }, draft => {
      //       draft.builds?.push({
      //         id: data.id,
      //         comp_id,
      //         role_id: role_id,
      //         one_per_party: false,
      //         items: []
      //       });
      //     })
      //   );
      // }
    }),

    deleteBuild: builder.mutation<void, { comp_id: number; build_id: number }>({
      query: data => ({
        url: `/${data.comp_id}/build/${data.build_id}/delete`,
        method: 'POST'
      }),
      invalidatesTags: (_, __, data) => [{ type: 'Comp', id: data.comp_id.toString() }],
      onQueryStarted: async ({ comp_id, build_id }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          comps.util.updateQueryData('getComp', { id: comp_id.toString() }, draft => {
            draft.builds = draft.builds?.filter(build => build.id !== build_id) || null;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),

    addItemToBuild: builder.mutation<
      Albion_Item,
      { comp_id: number; build_id: number; item: Albion_Item }
    >({
      query: data => ({
        url: `/${data.comp_id}/build/${data.build_id}/add-item`,
        method: 'POST',
        body: {
          item_id: data.item.id
        }
      }),
      onQueryStarted: async ({ comp_id, build_id, item }, { dispatch /*queryFulfilled*/ }) => {
        const temporaryId = Math.random();
        const patchResult = dispatch(
          comps.util.updateQueryData('getComp', { id: comp_id.toString() }, draft => {
            const build = draft.builds?.find(b => b.id === build_id);

            if (!build) {
              return;
            }

            if (item.slot === SlotType.Weapon) {
              build.items = build.items.filter(i => i.item?.slot !== SlotType.Weapon);
            }

            build.items.push({
              id: temporaryId,
              item_id: item.id,
              build_id,
              description: null,
              item: {
                ...item,
                available_spells: []
              },
              selected_spells: null
            } as CompBuildItem);
          })
        );
        try {
          // const { data: actualItem } = await queryFulfilled;
          // dispatch(
          //   comps.util.updateQueryData('getComp', { id: comp_id.toString() }, draft => {
          //     const build_index = draft.builds?.findIndex(i => i.id === build_id);
          //     if (!build_index) {
          //       return;
          //     }
          //
          //     const build = draft.builds?.[build_index];
          //     if (!build) {
          //       return;
          //     }
          //
          //     const itemIndex = build?.items.findIndex(i => i.id === temporaryId);
          //     if (typeof itemIndex !== 'undefined' && itemIndex !== -1 && draft.builds) {
          //       build.items[itemIndex] = actualItem;
          //     }
          //   })
          // );
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, data) => [{ type: 'Comp', id: data.comp_id.toString() }]
    }),

    removeItemFromBuild: builder.mutation<
      void,
      { comp_id: number; build_id: number; item_id: number }
    >({
      query: data => ({
        url: `/${data.comp_id}/build/${data.build_id}/remove-item`,
        method: 'POST',
        body: {
          item_id: data.item_id
        }
      }),
      invalidatesTags: (_, __, data) => [{ type: 'Comp', id: data.comp_id.toString() }],
      onQueryStarted: async ({ comp_id, build_id, item_id }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          comps.util.updateQueryData('getComp', { id: comp_id.toString() }, draft => {
            const build = draft.builds?.find(b => b.id === build_id);
            if (!build) {
              return;
            }

            build.items = build.items.filter(item => item?.item?.id !== item_id);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),

    toggleItemSpell: builder.mutation<
      void,
      { comp_id: number; build_id: number; item_id: number; spell_id: number; slot: string }
    >({
      query: data => ({
        url: `/${data.comp_id}/build/${data.build_id}/spells/toggle`,
        method: 'POST',
        body: {
          spell_id: data.spell_id,
          item_id: data.item_id,
          slot: data.slot
        }
      }),
      invalidatesTags: (_, __, data) => [{ type: 'Comp', id: data.comp_id.toString() }],
      onQueryStarted: async ({ comp_id, build_id, item_id, spell_id, slot }, { dispatch }) => {
        dispatch(
          comps.util.updateQueryData('getComp', { id: comp_id.toString() }, draft => {
            const build = draft.builds?.find(b => b.id === build_id);
            if (!build) {
              return;
            }

            const item = build.items.find(i => i.id === item_id);
            if (!item) {
              return;
            }

            if (!item.selected_spells) {
              return;
            }

            const spell = item.selected_spells.find(s => s.spell?.id === spell_id);
            if (!spell) {
              item.selected_spells = item.selected_spells.filter(s => s.spell?.slot !== slot);
              item.selected_spells.push({
                spell: {
                  id: spell_id,
                  icon: '',
                  name: '',
                  slot: slot,
                  identifier: '',
                  order: null
                }
              });
            } else {
              item.selected_spells = item.selected_spells.filter(s => s.spell?.id !== spell_id);
            }
          })
        );
      }
    }),

    getSlotItems: builder.query<
      (Albion_Item_Category & {
        subcategories: (Albion_Item_Subcategory & {
          items: Albion_Item[];
        })[];
      })[],
      { slot: SlotType }
    >({
      query: ({ slot }) => `/slot-items/${slot}`
    })
  })
});

export const {
  useGetCompListQuery,
  useCreateCompMutation,
  useGetCompQuery,
  useUpdateCompMutation,
  useDeleteCompMutation,
  useGetRolesQuery,
  useAddRoleToCompMutation,
  useGetSlotItemsQuery,
  useCreateBuildMutation,
  useDeleteBuildMutation,
  useAddItemToBuildMutation,
  useRemoveItemFromBuildMutation,
  useRemoveRoleFromCompMutation,
  useToggleItemSpellMutation
} = comps;
