import Grid from '@mui/material/Unstable_Grid2';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardActions,
  Chip,
  Paper,
  Stack
} from '@mui/material';
import {
  BooleanInput,
  Form,
  ImageField,
  ImageInput,
  NumberInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  TextInput
} from 'react-admin';
import { MarkdownEditor } from '../../../components/markdown-editor/MarkdownEditor';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useController, useWatch } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Markdown from 'react-markdown';
import styles from '../edit/EditEvent.module.less';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { RouterOutputs, trpc } from '../../../api/trpc';
import { Loader } from '../../../../../components/loader/Loader';
import '@mdxeditor/editor/style.css';

export const EventForm = ({ eventData }: { eventData?: RouterOutputs['events']['getEvent'] }) => {
  const [selectedTab, setSelectedTab] = useState<'existing' | 'new'>('existing');
  const { data: eventOptions } = trpc.events.getCreationOptions.useQuery();

  const createEvent = trpc.events.createEvent.useMutation();
  const navigate = useNavigate();

  if (!eventOptions) {
    return <Loader />;
  }

  return (
    <Form
      defaultValues={{
        ...eventData,
        start_time: eventData?.start_time ? dayjs(eventData.start_time).utc(true).format() : null,
        regear_rules_id: eventData?.regear_rule?.id
          ? eventData?.regear_rule?.id
          : eventData?.other_regear_rules
          ? -1
          : null,
        existing_image: eventData?.image_id
      }}
      onSubmit={async data => {
        let uploadedImage: {
          id: string;
          url: string;
        } | null = null;

        if (data.image && 'rawFile' in data.image && data.image.rawFile) {
          const formData = new FormData();
          formData.append('file', data.image.rawFile);

          uploadedImage = await fetch('/api/events/upload', {
            method: 'POST',
            body: formData
          }).then(res => res.json());
        }

        const newEvent = await createEvent.mutateAsync({
          id: eventData?.id,
          name: data.name,
          start_time: data.start_time,
          duration: data.duration,
          description: data.description,
          comp_id: data.comp_id,
          mass_location: data.mass_location,
          loot_split_rules_id: data.loot_split_rules_id,
          required_participation_time: data.required_participation_time,
          guild_split_requested: data.guild_split_requested,
          regear_rules_id: data.regear_rules_id,
          other_regear_rules: data.other_regear_rules,
          voice_channels: data.voice_channels,
          image_id: uploadedImage ? uploadedImage.id : data.existing_image
        });

        if (newEvent) {
          navigate(`/dashboard/events/${newEvent.id}`);
        }
      }}
    >
      <Grid container spacing={5} columnSpacing={2}>
        <Grid xs={8}>
          <Stack spacing={3} padding={2}>
            <Stack direction="row" spacing={2}>
              <TextInput
                label="Event Name"
                name="name"
                source="event_name"
                helperText={false}
                required={true}
                style={{ flexGrow: 1 }}
              />
              <EventTimeInput />
              <NumberInput
                name="duration"
                source="duration"
                label="Duration (minutes)"
                helperText={false}
                sx={{ maxWidth: '160px' }}
              />
            </Stack>
            <MarkdownEditor
              name="description"
              defaultValue={eventData?.description ?? ''}
              label="Event Description"
            />
            <Stack direction="row" spacing={3}>
              <SelectInput
                label="Comp/Builds"
                name="comp_id"
                source="comp_id"
                sx={{ flex: 1 }}
                choices={[{ id: 1, name: 'Normal Roles' }, ...eventOptions.comps]}
                helperText={false}
              />
              <TextInput
                label="Mass Location"
                name="mass_location"
                source="mass_location"
                defaultValue="Lagoon HQ"
                sx={{ flex: 1 }}
                helperText={false}
              />
            </Stack>
            <Stack direction="row" spacing={3}>
              <Stack sx={{ flex: 1 }} spacing={3}>
                <SelectInput
                  label="Loot Split Rules"
                  name="loot_split_rules_id"
                  source="loot_split_rules_id"
                  sx={{ width: '100%' }}
                  choices={eventOptions.lootSplitRules}
                  helperText={false}
                  required={true}
                />
                <LootSplitRulesDescription rules={eventOptions.lootSplitRules} />
              </Stack>
              <Stack sx={{ flex: 1 }} spacing={3}>
                <SelectInput
                  label="Regear Rules"
                  name="regear_rules_id"
                  source="regear_rules_id"
                  sx={{ width: '100%' }}
                  choices={[...eventOptions.regearRules, { id: -1, name: 'Other' }]}
                  helperText={false}
                  required={true}
                />
                <OtherRegearRules
                  rules={eventOptions.regearRules}
                  defaultValue={eventData?.other_regear_rules ?? ''}
                />
              </Stack>
            </Stack>

            <Stack direction="row" spacing={3}>
              <NumberInput
                label="Required Participation Time"
                name="required_participation_time"
                source="required_participation_time"
                helperText="Amount of paticipation time (in minutes) required to be part of loot split."
              />
              <BooleanInput
                label="Request Guild Buy Loot and Perform Split"
                name="guild_split_requested"
                source="guild_split_requested"
                helperText={false}
              />
            </Stack>

            <TabContext value={selectedTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(e, newTab) => setSelectedTab(newTab)}>
                  <Tab label="Existing Image" value="existing" />
                  <Tab label="Upload Image" value="new" />
                </TabList>
              </Box>
              <TabPanel value="existing" sx={{ padding: 1 }}>
                <ImageSelector
                  images={eventOptions.images}
                  defaultValue={eventData?.image_id ?? ''}
                />
              </TabPanel>
              <TabPanel value="new" sx={{ padding: 1 }}>
                <ImageInput
                  label="Image"
                  name="image"
                  source="image"
                  accept="image/png, image/jpg, image/jpeg"
                  helperText={false}
                  maxSize={5000000}
                  sx={{ '& .previews': { textAlign: 'center' } }}
                >
                  <ImageField
                    source="src"
                    sx={{
                      '& img': {
                        width: '100% !important',
                        height: 'auto !important',
                        maxHeight: 800
                      }
                    }}
                  />
                </ImageInput>
              </TabPanel>
            </TabContext>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ background: 'rgba(255,255,255,0.05)' }}
              >
                <h3>Advanced Settings</h3>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={3}>
                  <SelectArrayInput
                    label="Voice Channel(s)"
                    name="voice_channels"
                    source="voice_channels"
                    choices={eventOptions.voiceChannels}
                    defaultValue={['1178382335671009320']}
                    helperText={
                      <>
                        These channels will be used to automatically track event participation. You
                        usually just want the{' '}
                        <Chip label="📆║Event" size="small" component="span" /> channel.
                      </>
                    }
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Box sx={{ backgroundColor: 'rgba(255,255,255,0.05)', height: '100%', padding: 2 }}>
            {/* TODO: Finish integration with player activity times */}
            <h2>Players Available</h2>
            <p>Set a date and time to see player availability.</p>
          </Box>
        </Grid>
      </Grid>
      <CardActions sx={{ backgroundColor: 'rgba(0,0,0,0.51)', padding: 2 }}>
        <SaveButton label={eventData?.id ? 'Edit Event' : 'Create Event'} />
      </CardActions>
    </Form>
  );
};

const EventTimeInput = () => {
  const inputController = useController({
    name: 'start_time',
    defaultValue: null,
    rules: { required: true }
  });
  const { value, ...field } = inputController.field;

  return (
    <DateTimePicker
      disablePast
      views={['month', 'day', 'hours', 'minutes']}
      label="Start Date/Time"
      value={dayjs(value).local()}
      closeOnSelect={false}
      {...field}
    />
  );
};

const LootSplitRulesDescription = ({
  rules
}: {
  rules: RouterOutputs['events']['getCreationOptions']['lootSplitRules'];
}) => {
  const lootsplitRules = useWatch({ name: 'loot_split_rules_id' });
  const description = rules.find(rule => rule.id === lootsplitRules)?.description;

  if (!description) {
    return (
      <Paper elevation={4} sx={{ padding: 3, color: 'rgb(204,40,40)' }}>
        Please select which loot split rules to use for this event.
      </Paper>
    );
  }

  return (
    <Paper elevation={4} sx={{ padding: 3 }}>
      <Markdown>{description}</Markdown>
    </Paper>
  );
};

const OtherRegearRules = ({
  rules,
  defaultValue = ''
}: {
  rules: RouterOutputs['events']['getCreationOptions']['regearRules'];
  defaultValue: string;
}) => {
  const regearRules = useWatch({ name: 'regear_rules_id' });

  if (regearRules === -1) {
    return <MarkdownEditor name="other_regear_rules" defaultValue={defaultValue} />;
  }

  if (regearRules) {
    return (
      <Paper elevation={4} sx={{ padding: 3 }}>
        <Markdown>{rules.find(rule => rule.id === regearRules)?.description}</Markdown>
      </Paper>
    );
  }

  return (
    <Paper elevation={4} sx={{ padding: 3, color: 'rgb(204,40,40)' }}>
      Please select which regear rules to use for this event.
    </Paper>
  );
};

const ImageSelector = ({
  images,
  defaultValue = ''
}: {
  images: { id: string; url: string }[];
  defaultValue?: string;
}) => {
  const input = useController({ name: 'existing_image', defaultValue: defaultValue });

  return (
    <Box className={styles.imagesContainer}>
      {images.map(image => (
        <Paper
          key={image.id}
          elevation={4}
          sx={{ padding: 1 }}
          className={clsx(styles.image, {
            [styles.selected]: input.field.value === image.id
          })}
        >
          <img
            src={image.url}
            alt=""
            onClick={() => {
              input.field.onChange(image.id);
            }}
          />
        </Paper>
      ))}
    </Box>
  );
};
