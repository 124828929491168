import {
  PostgrestMaybeSingleResponse,
  PostgrestResponse,
  PostgrestSingleResponse
} from '@supabase/supabase-js';
import { notEmpty } from '../shared/NotEmpty';

export const rangeParamToRange = (range: string): number[] => {
  return range.slice(1, -1).split(',').map(Number);
};

export const strToNum = (str: string): number => {
  return parseInt(str.replace(/,/g, ''), 10) || 0;
};

export function fetchData<T>(
  defaultValue: T[],
  query: PostgrestResponse<T> | false | null | undefined
): T[];
export function fetchData<T>(
  defaultValue: T,
  query: PostgrestSingleResponse<T> | false | null | undefined
): T;
export function fetchData<T>(
  defaultValue: T | null,
  query: PostgrestMaybeSingleResponse<T> | false | null | undefined
): T | null;
export function fetchData<T>(
  defaultValue: T[] | T | null,
  query:
    | PostgrestResponse<T>
    | PostgrestSingleResponse<T>
    | PostgrestMaybeSingleResponse<T>
    | false
    | null
    | undefined
) {
  if (!query) {
    return defaultValue;
  }

  const { data, error } = query;

  if (error) {
    throw error;
  }

  if (Array.isArray(data)) {
    return data.filter(notEmpty) || (defaultValue as T[]);
  }

  return data || defaultValue;
}
