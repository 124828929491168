import styles from './Spinner.module.less';
import clsx from 'clsx';

export const Spinner = ({ size = 'normal' }: { size?: 'normal' | 'button' }) => {
  return (
    <span
      className={clsx(styles.spinner, {
        [styles.normal]: size === 'normal',
        [styles.button]: size === 'button'
      })}
    ></span>
  );
};
