import { ContentBox } from '../../components/ContentBox';
import styles from './Comps.module.less';
import { Title } from 'react-admin';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import {
  useCreateCompMutation,
  useDeleteCompMutation,
  useGetCompListQuery
} from '../../../../store/api/Comps';
import { Loader } from '../../../../components/loader/Loader';
import { usePerms } from '../../../../hooks/usePerms';
import {
  hasEntityPermissions,
  hasPermission,
  oneOf,
  Permissions
} from '../../../../../shared/Permissions';
import { useGetUserProfileQuery } from '../../../../store/api/User';

export const Comps = () => {
  const navigate = useNavigate();
  const { data: comps } = useGetCompListQuery(undefined);
  const [createComp, { isLoading }] = useCreateCompMutation();
  const [deleteComp] = useDeleteCompMutation();
  const permissions = usePerms();
  const { data: user } = useGetUserProfileQuery(undefined);

  if (!comps) {
    return <Loader />;
  }

  const canCreateComps = hasPermission(permissions, Permissions.CREATE_COMP);

  const canUseActions = hasPermission(
    permissions,
    oneOf([
      Permissions.EDIT_ANY_COMP,
      Permissions.EDIT_OWN_COMP,
      Permissions.DELETE_ANY_COMP,
      Permissions.DELETE_OWN_COMP
    ])
  );

  return (
    <ContentBox className={styles.container}>
      <Title title="Builds" />
      <h1>Builds</h1>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Build Name</th>
            <th>Created By</th>
            {canCreateComps && <th>Published?</th>}
            {canUseActions && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {comps
            .filter(comp => comp.id !== 1)
            .map(comp => {
              return (
                <tr key={comp.id}>
                  <td>
                    <Link to={`/dashboard/builds/${comp.id}`}>{comp.name}</Link>
                  </td>
                  <td>{comp.user?.albion_name}</td>
                  {canCreateComps && <td>{comp.published ? 'Yes' : 'No'}</td>}
                  {canUseActions && (
                    <td>
                      <div className={styles.actions}>
                        {hasEntityPermissions(
                          permissions,
                          Permissions.EDIT_OWN_COMP,
                          Permissions.EDIT_ANY_COMP,
                          comp.user?.id === user?.id
                        ) && (
                          <button
                            onClick={() => {
                              navigate(`/dashboard/builds/${comp.id}/create`);
                            }}
                          >
                            <Edit sx={{ fontSize: 15 }} /> Edit
                          </button>
                        )}
                        {hasEntityPermissions(
                          permissions,
                          Permissions.DELETE_OWN_COMP,
                          Permissions.DELETE_ANY_COMP,
                          comp.user?.id === user?.id
                        ) && (
                          <button
                            onClick={async () => {
                              await deleteComp({ id: comp.id });
                            }}
                          >
                            <Delete sx={{ fontSize: 15 }} /> Delete
                          </button>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          {canCreateComps && (
            <tr>
              <td colSpan={canCreateComps ? 4 : 2} className={styles.newCompRow}>
                <button
                  className={styles.newComp}
                  disabled={isLoading}
                  onClick={async () => {
                    const response = await createComp(undefined);

                    if ('error' in response) {
                      console.error(response.error);
                      return;
                    }

                    navigate(`/dashboard/builds/${response.data.id}/create`);
                  }}
                >
                  <Add /> New Comp
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </ContentBox>
  );
};
