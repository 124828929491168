import { ContentBox } from '../../../components/ContentBox';
import { Title } from 'react-admin';
import { Card } from '@mui/material';
import { EventForm } from '../components/EventForm';

export const CreateEvent = () => {
  return (
    <ContentBox>
      <Title title="Create Event" />
      <h1 style={{ marginLeft: '10px' }}>Create Event</h1>
      <Card>
        <EventForm />
      </Card>
    </ContentBox>
  );
};
