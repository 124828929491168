import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const discord = createApi({
  reducerPath: 'discord',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/discord' }),
  endpoints: builder => ({
    getVoiceChannels: builder.query({
      query: () => `/voice-channels`
    })
  })
});

export const { useGetVoiceChannelsQuery } = discord;
