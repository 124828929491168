import React from 'react';
import styles from './GuildActivities.module.less';
import { ContentBox } from '../../../components/ContentBox';
import { Title } from 'react-admin';
import { Loader } from '../../../../../components/loader/Loader';
import { PlaytimesChart } from '../../../../../components/playtimes-chart/PlaytimesChart';
import { trpc } from '../../../api/trpc';

export const GuildActivities = () => {
  return (
    <ContentBox>
      <Title title="Guild Activities" />
      <SurveyResults />
    </ContentBox>
  );
};

const SurveyResults = () => {
  const { data, isPending, isError } = trpc.activities.getActivityCounts.useQuery();

  if (isPending) {
    return <Loader />;
  }

  if (isError || !data) {
    return <div>Error loading data</div>;
  }

  const max = data.reduce((acc, activity) => {
    return Math.max(acc, activity.lethalCount || 0, activity.nonLethalCount || 0);
  }, 0);

  return (
    <>
      <h1 style={{ textAlign: 'center', margin: 20, marginBottom: 5 }}>Guild Play Times</h1>
      {/*<h3 style={{ textAlign: 'center', margin: 20, marginTop: 5 }}>Responses: {results.count}</h3>*/}
      <PlaytimesChart />

      <div className={styles.groupActivities}>
        <h1>Group Activities</h1>
        <div className={styles.activitiesKey}>
          <div className={styles.keyLabel}>
            <div className={styles.nonLethal} style={{ width: 10, height: 10 }} />
            <span>Non-Lethal</span>
          </div>
          <div className={styles.keyLabel}>
            <div className={styles.lethal} style={{ width: 10, height: 10 }} />
            <span>Lethal</span>
          </div>
        </div>
        <table className={styles.activitiesTable} cellSpacing={10}>
          <tbody>
            {data.map(activity => {
              let nonLethalCount = activity.nonLethalCount || 0;
              let lethalCount = activity.lethalCount || 0;

              return (
                <tr className={styles.activity} key={activity.id}>
                  <td className={styles.activityName}>{activity.name}</td>
                  <td className={styles.activityCounts}>
                    {activity.is_non_lethal && (
                      <div
                        className={styles.nonLethal}
                        style={{
                          width: `${Math.floor((nonLethalCount / max) * 100)}%`,
                          minWidth: 'fit-content'
                        }}
                      >
                        {nonLethalCount}
                      </div>
                    )}
                    {activity.is_lethal && (
                      <div
                        className={styles.lethal}
                        style={{
                          width: `${Math.floor((lethalCount / max) * 100)}%`,
                          minWidth: 'fit-content'
                        }}
                      >
                        {lethalCount}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/*<table className={styles.activitiesTable} cellSpacing={10}>*/}
        {/*  <tbody>*/}
        {/*    {ResultsParser.activities.map(activity => {*/}
        {/*      const key = ResultsParser.ActivityText.replace('{ACTIVITY}', activity);*/}
        {/*      let nonLethalCount = results?.activitiesCounts[key]?.['nonLethal'] || 0;*/}
        {/*      let lethalCount = results?.activitiesCounts[key]?.['lethal'] || 0;*/}
        {/*      const isNonLethal = ResultsParser.nonLethalActivities.includes(activity);*/}
        {/*      const isLethal = ResultsParser.lethalActivities.includes(activity);*/}
        {/*      const max = results?.activitiesCounts.max || 1;*/}

        {/*      if (!isNonLethal) {*/}
        {/*        lethalCount = Math.max(lethalCount, nonLethalCount);*/}
        {/*      }*/}

        {/*      if (!isLethal) {*/}
        {/*        nonLethalCount = Math.max(lethalCount, nonLethalCount);*/}
        {/*      }*/}

        {/*      return (*/}
        {/*        <tr className={styles.activity} key={activity}>*/}
        {/*          <td className={styles.activityName}>{activity}</td>*/}
        {/*          <td className={styles.activityCounts}>*/}
        {/*            {isNonLethal && (*/}
        {/*              <div*/}
        {/*                className={styles.nonLethal}*/}
        {/*                style={{*/}
        {/*                  width: `${Math.floor((nonLethalCount / max) * 100)}%`*/}
        {/*                }}*/}
        {/*              >*/}
        {/*                {nonLethalCount}*/}
        {/*              </div>*/}
        {/*            )}*/}
        {/*            {isLethal && (*/}
        {/*              <div*/}
        {/*                className={styles.lethal}*/}
        {/*                style={{*/}
        {/*                  width: `${Math.floor((lethalCount / max) * 100)}%`*/}
        {/*                }}*/}
        {/*              >*/}
        {/*                {lethalCount}*/}
        {/*              </div>*/}
        {/*            )}*/}
        {/*          </td>*/}
        {/*        </tr>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </tbody>*/}
        {/*</table>*/}
      </div>
    </>
  );
};

type Row = {
  Timestamp: string;
  'Your In-Game Name': string;
  'Play Times (UTC - Game Time) [0:00 - 1:00]': string;
  'Play Times (UTC - Game Time) [1:00 - 2:00]': string;
  'Play Times (UTC - Game Time) [2:00 - 3:00]': string;
  'Play Times (UTC - Game Time) [3:00 - 4:00]': string;
  'Play Times (UTC - Game Time) [4:00 - 5:00]': string;
  'Play Times (UTC - Game Time) [5:00 - 6:00]': string;
  'Play Times (UTC - Game Time) [6:00 - 7:00]': string;
  'Play Times (UTC - Game Time) [7:00 - 8:00]': string;
  'Play Times (UTC - Game Time) [8:00 - 9:00]': string;
  'Play Times (UTC - Game Time) [9:00 - 10:00]': string;
  'Play Times (UTC - Game Time) [10:00 - 11:00]': string;
  'Play Times (UTC - Game Time) [11:00 - 12:00]': string;
  'Play Times (UTC - Game Time) [12:00 - 13:00]': string;
  'Play Times (UTC - Game Time) [13:00 - 14:00]': string;
  'Play Times (UTC - Game Time) [14:00 - 15:00]': string;
  'Play Times (UTC - Game Time) [15:00 - 16:00]': string;
  'Play Times (UTC - Game Time) [16:00 - 17:00]': string;
  'Play Times (UTC - Game Time) [17:00 - 18:00]': string;
  'Play Times (UTC - Game Time) [18:00 - 19:00]': string;
  'Play Times (UTC - Game Time) [19:00 - 20:00]': string;
  'Play Times (UTC - Game Time) [20:00 - 21:00]': string;
  'Play Times (UTC - Game Time) [21:00 -22:00]': string;
  'Play Times (UTC - Game Time) [22:00 - 23:00]': string;
  'Play Times (UTC - Game Time) [23:00 - 00:00]': string;
  'What types of group activities would you like to participate in? [Group Dungeons]': string;
  'What types of group activities would you like to participate in? [Large Group Dungeons]': string;
  'What types of group activities would you like to participate in? [Static Dungeons for Chests]': string;
  'What types of group activities would you like to participate in? [Static Dungeons for Fame Farming]': string;
  'What types of group activities would you like to participate in? [Killing overworld mobs for Fame Farming]': string;
  'What types of group activities would you like to participate in? [World Bosses]': string;
  'What types of group activities would you like to participate in? [Ganking]': string;
  'What types of group activities would you like to participate in? [Low Tier/IP BZ Roaming]': string;
  'What types of group activities would you like to participate in? [High Tier/IP BZ Roaming]': string;
  'What types of group activities would you like to participate in? [Capturing Outposts]': string;
  'What types of group activities would you like to participate in? [Capturing Territories]': string;
  'What types of group activities would you like to participate in? [Territory Raiding (Siphoning Mages)]': string;
  'What types of group activities would you like to participate in? [Any ZvZ]': string;
  'What types of group activities would you like to participate in? [Faction Warfare]': string;
  'What types of group activities would you like to participate in? [Arenas]': string;
  'What types of group activities would you like to participate in? [Crystal Arenas]': string;
  'What types of group activities would you like to participate in? [5v5 Hellgates]': string;
  'What types of group activities would you like to participate in? [10v10 Hellgates]': string;
  'What types of group activities would you like to participate in? [Hardcore Expeditions]': string;
  'What types of group activities would you like to participate in? [Dueling Guild Tournaments (Bracketed)]': string;
  'What types of group activities would you like to participate in? [City-to-City Transports (Royals)]': string;
  'What types of group activities would you like to participate in? [City-to-City Transports (Outlands)]': string;
  'What types of group activities would you like to participate in? [Gathering Parties]': string;
  'What types of group activities would you like to participate in? [Tracking]': string;
  'What types of group activities would you like to participate in? [Avalonian Roads]': string;
  'What other activities would you like to do with the guild?': string;
};

type Sheet = Row[];

class ResultsParser {
  private rawResults: Sheet = [];

  public static TimeText = 'Play Times (UTC - Game Time) [{TIME}]';
  public static TIMES = [
    '0:00 - 1:00',
    '1:00 - 2:00',
    '2:00 - 3:00',
    '3:00 - 4:00',
    '4:00 - 5:00',
    '5:00 - 6:00',
    '6:00 - 7:00',
    '7:00 - 8:00',
    '8:00 - 9:00',
    '9:00 - 10:00',
    '10:00 - 11:00',
    '11:00 - 12:00',
    '12:00 - 13:00',
    '13:00 - 14:00',
    '14:00 - 15:00',
    '15:00 - 16:00',
    '16:00 - 17:00',
    '17:00 - 18:00',
    '18:00 - 19:00',
    '19:00 - 20:00',
    '20:00 - 21:00',
    '21:00 -22:00',
    '22:00 - 23:00',
    '23:00 - 00:00'
  ];

  public static ActivityText =
    'What types of group activities would you like to participate in? [{ACTIVITY}]';
  public static activities = [
    'Group Dungeons',
    'Large Group Dungeons',
    'Static Dungeons for Chests',
    'Static Dungeons for Fame Farming',
    'Killing overworld mobs for Fame Farming',
    'World Bosses',
    'Ganking',
    'Low Tier/IP BZ Roaming',
    'High Tier/IP BZ Roaming',
    'Capturing Outposts',
    'Capturing Territories',
    'Territory Raiding (Siphoning Mages)',
    'Any ZvZ',
    'Faction Warfare',
    'Arenas',
    'Crystal Arenas',
    '5v5 Hellgates',
    '10v10 Hellgates',
    'Hardcore Expeditions',
    'Dueling Guild Tournaments (Bracketed)',
    'City-to-City Transports (Royals)',
    'City-to-City Transports (Outlands)',
    'Gathering Parties',
    'Tracking',
    'Avalonian Roads'
  ];

  public static nonLethalActivities = [
    'Group Dungeons',
    'Static Dungeons for Chests',
    'Static Dungeons for Fame Farming',
    'Killing overworld mobs for Fame Farming',
    'World Bosses',
    'Ganking',
    'Any ZvZ',
    'Faction Warfare',
    'Arenas',
    'Crystal Arenas',
    '5v5 Hellgates',
    '10v10 Hellgates',
    'Hardcore Expeditions',
    'Dueling Guild Tournaments (Bracketed)',
    'City-to-City Transports (Royals)',
    'Gathering Parties'
  ];

  public static lethalActivities = [
    'Group Dungeons',
    'Large Group Dungeons',
    'Static Dungeons for Chests',
    'Static Dungeons for Fame Farming',
    'Killing overworld mobs for Fame Farming',
    'World Bosses',
    'Ganking',
    'Low Tier/IP BZ Roaming',
    'High Tier/IP BZ Roaming',
    'Capturing Outposts',
    'Capturing Territories',
    'Territory Raiding (Siphoning Mages)',
    'Any ZvZ',
    'Faction Warfare',
    '5v5 Hellgates',
    '10v10 Hellgates',
    'City-to-City Transports (Royals)',
    'City-to-City Transports (Outlands)',
    'Gathering Parties',
    'Tracking',
    'Avalonian Roads'
  ];

  public static activitiesFull = ResultsParser.activities.map(activity =>
    ResultsParser.ActivityText.replace('{ACTIVITY}', activity)
  );

  public static parseResults(data: Sheet) {
    if (!data) {
      return [];
    }

    const byPlayer: Record<Row['Your In-Game Name'], Row> = {};

    // Ensure we only have the latest data from a player
    data.forEach((row: Row) => {
      const player = row['Your In-Game Name'].toLowerCase().trim();
      byPlayer[player] = row;
    });

    const results = Object.values(byPlayer);

    return {
      byDayTime: ResultsParser.getPlayersOnlineByDayAndTime(results),
      activitiesCounts: ResultsParser.getActivitiesCounts(results),
      otherIdeas: ResultsParser.getOtherIdeas(results),
      count: results.length
    };
  }

  public static getOtherIdeas(data: Sheet) {
    return data
      .map(row => ({
        idea: row['What other activities would you like to do with the guild?'],
        player: row['Your In-Game Name']
      }))
      .filter(idea => Boolean(idea.idea));
  }

  public static getActivitiesCounts(data: Sheet) {
    const activities: Record<string, { lethal: number; nonLethal: number }> = {};
    let max = 0;

    data.forEach(row => {
      const keys = Object.keys(row);
      keys.forEach(key => {
        const types = row[key as keyof Row]
          .split(',')
          .map(type => type.trim())
          .filter(type => Boolean(type));

        if (ResultsParser.activitiesFull.includes(key)) {
          activities[key] = activities[key] || {
            lethal: 0,
            nonLethal: 0
          };

          if (types.includes('Lethal')) {
            activities[key]['lethal'] = activities[key]['lethal'] + 1;
          }

          if (types.includes('NON-Lethal')) {
            activities[key]['nonLethal'] = activities[key]['nonLethal'] + 1;
          }

          max = Math.max(activities[key]['lethal'], activities[key]['nonLethal'], max);
        }
      });
    });

    return { ...activities, max };
  }

  public static getPlayersOnlineByDayAndTime(data: Sheet) {
    const finalData: any = {};
    let max = 0;

    data.forEach(row => {
      const keys = Object.keys(row);
      keys.forEach(key => {
        if (key.indexOf('UTC') !== -1) {
          const days = ResultsParser.getDaysForTime(row[key as keyof Row]);
          days.forEach(day => {
            if (day.length === 0) return;
            const currentCount = finalData[day]?.[key] || 0;
            finalData[day] = finalData[day] || {};
            finalData[day][key] = currentCount + 1;
            if (currentCount + 1 > max) {
              max = currentCount + 1;
            }
          });
        }
      });
    });

    return { ...finalData, max };
  }

  public static getDaysForTime(dayString: string) {
    return dayString.split(',').map(day => day.trim());
  }
}

function convertUTCHourToLocal(hourUTC: string) {
  // Create a Date object for today's date at the UTC hour specified
  const now = new Date();
  const today = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), parseInt(hourUTC, 10))
  );

  // Convert the UTC date object to the local time zone
  const localHour = today.getHours();

  // Determine AM or PM
  const ampm = localHour >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  const hour12 = localHour % 12 || 12; // Convert 0 to 12 for 12 AM

  return {
    hour: hour12,
    ampm: ampm
  };
}
