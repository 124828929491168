import React, { useEffect, useState } from 'react';
import styles from './home.module.less';
import { SmallBox } from '../../components/small-box/SmallBox';
import clsx from 'clsx';
import { Layout } from '../../layout/Layout';
import { Provider } from 'react-redux';
import { store } from '../../store/Store';

export const Home = () => {
  return (
    <Provider store={store}>
      <Layout>
        <div className={styles.outerContainer}>
          <div className={styles.container}>
            <div className={styles.left}>
              <section className={styles.hero}>
                <div>
                  <h1>Welcome to Assassin’s Greed!</h1>
                  <p>
                    Assassin's Greed is an emerging guild within Albion Online, anchored in the city
                    of Bridgewatch. Laid back, friendly, and open to new players, we pride ourselves
                    on being welcoming and well organized. Everyone has a place in Assassin's Greed,
                    from the casual weekend player just looking to chill and gather, to the hardcore
                    PvPer who looks for fights and fame every day. We have no mandatory CTAs, but
                    instead have an opt-in system for those who wish to participate in GvG content.
                    For those wishing to rise through the ranks and help build the guild,
                    opportunities are abundant! Come join!
                  </p>
                </div>
                <DiscordBlock />
              </section>

              <DetailsBar className={styles.mobileDetails} />

              <section style={{ padding: 30 }}>
                <h2>Guild Culture:</h2>
                <p>
                  We’re a laid back guild who is most interested in having fun and playing the game
                  with others. As such, any sort of toxicity, divisiveness, or deception by members
                  will be quickly addressed with a warning and then a kick if it doesn’t stop. We
                  are welcoming to new players - anyone pressuring others or insulting them for not
                  being amazing at the game or not understanding mechanics will not have a place
                  here.
                </p>
                <br />
                <p style={{ fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center' }}>
                  Rule #1 is to be awesome to each other.
                </p>
                <br />
                <p>
                  Also, there's absolutely no pressure to commit to every single event, and nothing
                  will ever be made into a mandatory event for the entire guild. Anything that we
                  wish to engage in that requires commitment from any guild members will be entirely
                  opt-in.
                </p>
              </section>

              <section style={{ padding: 30 }}>
                <h2>Membership & Structure:</h2>
                <p>
                  Anyone can join as a recruit. By becoming active in Discord and getting verified,
                  you can become a member. Members also have the opportunity to become a Content
                  Creator - a person who can post events and who has gained a small amount of trust
                  with leadership.
                </p>
              </section>

              <section>
                <div style={{ padding: 30, paddingBottom: 0 }}>
                  <h2>Activities We Engage In:</h2>
                  <p>
                    For all the various activities in Albion that members have expressed interest
                    in, we have roles in discord that members can opt into and be pinged by other
                    members looking for a group to join up with.
                  </p>
                </div>

                <div className={styles.activities}>
                  <ActivitiesBlock
                    name="ZvZs"
                    description="As we've established ourselves in the Outlands, our members have trained in
                small scale ZvZ fights and are always improving. We frequently run events that involve ZvZ fights
                both in the Black Zone and during Faction Warfare"
                    image="/activities/zvz.png"
                    side="left"
                  />

                  <ActivitiesBlock
                    name="PvP and Ganking"
                    description="Our guild is not averse to engaging in both organized skirmishes and
              opportunistic ganks. We occasionally form parties specifically to go ganking in the
              Outlands near our HQ and in Ava Roads."
                    image="/activities/ganking.png"
                    side="left"
                  />

                  <ActivitiesBlock
                    name="Group Fame Farming"
                    description="We frequently group up to go fame farming in the black zone,
              either by killing overworld mobs, doing static dungeons, or finding group dungeons in safe(er) areas."
                    image="/activities/fame-farming.png"
                    side="left"
                  />

                  <ActivitiesBlock
                    name="Faction Warfare"
                    description="We align with and fight for Bridgewatch. Members are frequently joining FW blobs,
                and we run our own parties to crush our neighbors Martlock and Lymhurst. Our members participate
                in both Yellow Zone and Red Zone Faction Warfare"
                    image="/activities/faction-warfare.png"
                    side="left"
                  />

                  <ActivitiesBlock
                    name="All-the-Dungeons"
                    description="Whether it's in the yellow or black zones, our members frequently plunge into the
                depths. Whether you're looking for a group to run dungeons with, or just need some advice on
                what builds to use, you can usually find someone online to help."
                    image="/activities/dungeons.png"
                    side="left"
                  />

                  <ActivitiesBlock
                    name="Gathering"
                    description="Frequent excursions are made to gather resources, with group gathering
              activities in the Avalonian Roads and Outlands. You can often find members willing to accompany you
              into dangerous zones to gather resources."
                    image="/activities/gathering.png"
                    side="left"
                  />

                  <ActivitiesBlock
                    name="Crafting"
                    description="We have full crafting stations in our BZ HQ and on our guild island available for full members to
              use, and with many crafters in the guild, you can usually find someone to craft the
              gear you need cheaper than buying it from the market."
                    image="/activities/crafting.png"
                    side="left"
                  />

                  <ActivitiesBlock
                    name="Arena"
                    description="Many members enjoy engaging in the Arena to practice small group fights. We're currently
                recruiting members who wish to participate in Areans and step up to Hellgates."
                    image="/activities/arena.png"
                    side="left"
                  />

                  {/*<ActivitiesBlock*/}
                  {/*  name="Hardcore Expeditions (HCEs)"*/}
                  {/*  description="Join our HCE team to run hardcore expeditions and make mad silver. We have members who are*/}
                  {/*familiar with and skilling HCEs who can help you get the right builds together and teach you how to run*/}
                  {/*HCEs efficiently."*/}
                  {/*  image="/activities/hces.png"*/}
                  {/*  side="left"*/}
                  {/*/>*/}
                </div>
              </section>
            </div>
            <div className={styles.right}>
              <DetailsBar className={styles.desktopDetails} />
            </div>
          </div>
        </div>
      </Layout>
    </Provider>
  );
};

const DetailsBar = ({ className }: { className: string }) => {
  return (
    <div className={clsx(styles.details, className)}>
      <SmallBox heading="Season 23 Guild Highlights">
        <ul className={styles.guildHighlights}>
          <li>Silver Ranked (#214)</li>
          <li>2 Hideouts in Bridgewatch Black Zone</li>
          <li>Full T8 Regears for Guild Events</li>
          <li>Lucrative Guild Season Rewards</li>
          <ul>
            <li>A Transport Mammoth</li>
            <li>8.4 weapons (totalling 250+ million silver)</li>
            <li>over 50 million in silver payouts to season point contributors</li>
          </ul>
          <li>Daily Organized Content, Including:</li>
          <ul>
            <li>Black Zone Roams</li>
            <li>ZvZ and Small Scale PvP</li>
            <li>AVA Raids</li>
            <li>Static and Roads Fame Farming</li>
            <li>Tracking</li>
            <li>Faction Warfare</li>
          </ul>
        </ul>
      </SmallBox>

      <SmallBox heading="Requirements to Join">
        <p style={{ fontSize: '2em', textAlign: 'center', marginBottom: 15 }}>6 Million Fame</p>
        <p>
          Low fame limit to allow newer players to join, without allowing those who just started the
          game and have not invested any time or effort into it.
        </p>
      </SmallBox>

      <SmallBox heading="Home City & Guild Island">
        <p
          style={{
            fontSize: '2em',
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src="/bridgewatch-icon.png" alt="Bridgewatch" />
          Bridgewatch
        </p>
      </SmallBox>

      <SmallBox heading="Region">
        <p style={{ fontSize: '2em', textAlign: 'center' }}>Americas</p>
      </SmallBox>

      <SmallBox heading="Language">
        <p style={{ fontSize: '2em', textAlign: 'center' }}>English</p>
      </SmallBox>

      <SmallBox heading="Most Active Times">
        <p style={{ fontSize: '2em', textAlign: 'center' }}>16:00 - 05:00 UTC</p>
        <p style={{ fontSize: '2em', textAlign: 'center' }}>12:00pm - 1:00am EST</p>
        <p style={{ fontSize: '0.8em', textAlign: 'center', marginTop: 15 }}>
          Our guild has members from around the world. Voice comms are most active during EST
          evenings on weekdays and throughout the day during weekends. However, there's almost
          always some activity at all hours.
        </p>
      </SmallBox>

      <SmallBox heading="Silver Tax">
        <p style={{ fontSize: '2em', textAlign: 'center' }}>5%</p>
      </SmallBox>
    </div>
  );
};

const DiscordBlock = () => {
  const [memberCount, setMemberCount] = useState(0);

  useEffect(() => {
    fetch('/api/members/member-count')
      .then(res => res.json())
      .then(data => {
        setMemberCount(data.count);
      })
      .catch(e => console.error(e));
  }, []);

  return (
    <div className={styles.discordBlock}>
      <a
        href="https://discord.gg/TWasd4rrph"
        className={styles.discord}
        target="_blank"
        rel="noreferrer"
      >
        <p>Join us on</p>
        <img src="/discord-logo-white.svg" width="100%" alt="Discord" />
      </a>
      <div className={styles.discordCounters}>
        <Counter name="Members In Discord" count={memberCount} />
      </div>
    </div>
  );
};

interface CounterProps {
  name: string;
  count: number;
}
const Counter = ({ name, count }: CounterProps) => {
  return (
    <div className={styles.counter}>
      <span className={styles.counterCount}>{count}</span>
      <span className={styles.counterName}>{name}</span>
    </div>
  );
};

interface ActivityBlockProps {
  name: string;
  description: string;
  image: string;
  side: 'left' | 'right';
}

const ActivitiesBlock = ({ name, description, image, side }: ActivityBlockProps) => {
  return (
    <div className={styles.activity}>
      {side === 'left' && (
        <div className={styles.activityImage}>
          <img src={image} alt={name} />
        </div>
      )}

      <div>
        <h2>{name}</h2>
        <p>{description}</p>
      </div>

      {side === 'right' && (
        <div className={styles.activityImage}>
          <img src={image} alt={name} />
        </div>
      )}
    </div>
  );
};
