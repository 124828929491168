import React from 'react';
import styles from './SmallBox.module.less';
import { HeadingBox } from '../heading-box/HeadingBox';

interface SmallBoxProps {
  children: React.ReactNode;
  heading: string;
}
export const SmallBox = ({ children, heading }: SmallBoxProps) => {
  return (
    <div className={styles.smallBox}>
      <HeadingBox>{heading}</HeadingBox>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
