import { useGetUserProfileQuery } from '../store/api/User';
import { Permissions } from '../../shared/Permissions';
import { Role } from '../../shared/types/database/database';

export const usePerms = (): Permissions[] => {
  const { data: user } = useGetUserProfileQuery(undefined);
  return user ? user.permissions : [];
};

export const useRole = (): Role => {
  const { data: user } = useGetUserProfileQuery(undefined);
  return user ? user.role : [];
};
