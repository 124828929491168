import { user } from './User';
import { comps } from './Comps';
import { discord } from './Discord';

export const apiMiddleware = [user.middleware, comps.middleware, discord.middleware];

export const apiReducers = {
  user: user.reducer,
  comps: comps.reducer,
  discord: discord.reducer
};

export const apiReducerPaths = [user.reducerPath, comps.reducerPath, discord.reducerPath];
