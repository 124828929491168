import React, { useContext, useState } from 'react';
import styles from './CreateComp.module.less';
import { Title } from 'react-admin';
import { ContentBox } from '../../../components/ContentBox';
import { useParams } from 'react-router-dom';
import {
  comps,
  useAddItemToBuildMutation,
  useAddRoleToCompMutation,
  useCreateBuildMutation,
  useDeleteBuildMutation,
  useGetCompQuery,
  useGetRolesQuery,
  useGetSlotItemsQuery,
  useRemoveItemFromBuildMutation,
  useRemoveRoleFromCompMutation,
  useToggleItemSpellMutation,
  useUpdateCompMutation
} from '../../../../../store/api/Comps';
import Edit from '@mui/icons-material/Edit';
import Check from '@mui/icons-material/Check';
import { Loader } from '../../../../../components/loader/Loader';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack
} from '@mui/material';
import Plus from '@mui/icons-material/Add';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Delete from '@mui/icons-material/Delete';
import {
  Albion_Item_Spell,
  Comp_Role_Type
} from '../../../../../../shared/types/database/database';
import {
  AlbionSpell,
  CompBuild,
  CompBuildItem,
  CompRole,
  SlotType
} from '../../../../../../shared/types/api/Comps';
import { DiscordEmoji } from '../../../../../components/discord-emoji/DiscordEmoji';
import clsx from 'clsx';
import { Tooltip } from 'react-tooltip';
import { Spinner } from '../../../../../components/spinner/Spinner';
import { trpc } from '../../../api/trpc';
import { useDispatch } from 'react-redux';

const CompContext = React.createContext<{
  comp_id: number | null;
  selectedRoleId: number | null;
  selectedBuildId: number | null;
  isCompFetching: boolean;
}>({
  comp_id: null,
  selectedRoleId: null,
  selectedBuildId: null,
  isCompFetching: false
});

export const CreateComp = () => {
  const { id } = useParams() as { id: string };
  const { data: comp, isFetching: isCompFetching } = useGetCompQuery({ id });
  const { data: roles } = useGetRolesQuery(undefined);
  const [updateComp] = useUpdateCompMutation();
  const [selectedRoleIdState, setSelectedRoleId] = useState<number | null>(null);
  const [selectedBuildId, setSelectedBuildId] = useState<number | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<SlotType | null>(null);

  if (!comp || !roles) {
    return <Loader />;
  }

  const selectedRoleId = selectedRoleIdState || comp?.roles?.[0]?.id;
  const selectedRoleData = comp.roles?.find(role => role?.id === selectedRoleId);
  const builds = comp.builds || [];
  const selectedBuild = builds.find(build => build?.id === selectedBuildId);

  return (
    <CompContext.Provider
      value={{
        comp_id: comp.id,
        selectedRoleId,
        selectedBuildId,
        isCompFetching
      }}
    >
      <ContentBox padding={1}>
        <Title title={'Create Comp'} />
        <TitleBox
          title={comp.name || 'New Comp'}
          onTitleChange={(name: string) => {
            updateComp({ id: comp.id, name });
          }}
          isPublished={comp.published}
          comp_id={comp.id}
        />
        <div
          className={clsx(styles.container, {
            [styles.viewList]: selectedBuildId === null,
            [styles.viewBuild]: selectedBuildId !== null
          })}
        >
          <RoleListPane
            comp_id={comp.id}
            roles={roles}
            currentRoles={comp.roles}
            selectedRole={selectedRoleId}
            onRoleSelected={roleId => {
              setSelectedRoleId(roleId);
              setSelectedBuildId(null);
              setSelectedSlot(null);
            }}
            isBuildSelected={selectedBuildId !== null}
          />{' '}
          {selectedRoleData && (
            <BuildListPane
              builds={builds}
              onBuildSelected={(buildId, slotSelected) => {
                setSelectedBuildId(buildId);
                setSelectedSlot(slotSelected);
              }}
              selectedBuildId={selectedBuildId}
              selectedRole={selectedRoleData}
              comp_id={comp.id}
            />
          )}
          {selectedBuild && (
            <BuildViewPane
              build={selectedBuild}
              selectedSlot={selectedSlot}
              onSlotSelected={setSelectedSlot}
            />
          )}
          {selectedSlot && (
            <ItemsPane
              slot={selectedSlot}
              selectedItemIds={selectedBuild?.items
                ?.filter(item => selectedSlot === item?.item?.slot)
                .map(item => item?.item?.id)}
            />
          )}
        </div>
      </ContentBox>
    </CompContext.Provider>
  );
};

const TitleBox = ({
  title,
  onTitleChange,
  isPublished,
  comp_id
}: {
  title: string;
  onTitleChange: (title: string) => void;
  isPublished?: boolean;
  comp_id: number;
}) => {
  const dispatch = useDispatch();
  const [isEditingCompName, setIsEditingCompName] = React.useState(false);
  const [compName, setCompName] = React.useState(title);
  const { mutateAsync: togglePublished } = trpc.comps.togglePublished.useMutation({
    onSuccess: () => {
      dispatch(comps.util.invalidateTags([{ type: 'Comp', id: comp_id.toString() }]));
    }
  });

  return (
    <div className={styles.titleBox}>
      <div className={styles.title}>
        {isEditingCompName ? (
          <input
            type="text"
            value={compName}
            onChange={e => setCompName(e.target.value)}
            className={styles.titleInput}
          />
        ) : (
          <h1>{compName}</h1>
        )}
        {!isEditingCompName ? (
          <Edit
            onClick={() => setIsEditingCompName(true)}
            className={styles.titleButton}
            fontSize="small"
          />
        ) : (
          <Check
            onClick={() => {
              setIsEditingCompName(false);
              onTitleChange(compName);
            }}
            className={styles.titleButton}
            fontSize="small"
          />
        )}
      </div>

      <Button
        variant="contained"
        color={isPublished ? 'secondary' : 'primary'}
        onClick={() =>
          togglePublished({
            comp_id,
            published: !isPublished
          })
        }
      >
        {isPublished ? 'Unpublish' : 'Publish'}
      </Button>
    </div>
  );
};

const RoleListPane = ({
  comp_id,
  roles,
  currentRoles,
  selectedRole,
  onRoleSelected,
  isBuildSelected
}: {
  comp_id: number;
  roles: Comp_Role_Type[];
  currentRoles: CompRole[] | null;
  selectedRole: number | null;
  onRoleSelected: (role: number | null) => void;
  isBuildSelected: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedRoleToAdd, setSelectedRoleToAdd] = React.useState<number | null>(null);
  const [addRole, { isLoading }] = useAddRoleToCompMutation();

  const rolesToSelectFrom = roles.filter(
    role => !currentRoles?.find(currentRole => currentRole?.role_type_id === role.id)
  );

  return (
    <Stack
      sx={{ height: '100%', padding: 2, width: isBuildSelected ? 100 : '100%' }}
      component={Paper}
      spacing={2}
      className={styles.roleListPane}
    >
      {[...(currentRoles || [])]
        ?.sort((roleA, roleB) => (roleA?.order || 99999) - (roleB?.order || 99999))
        .map(role => {
          if (!role || !role?.type) {
            return null;
          }

          const isSelected = selectedRole === role.id;

          return (
            <Button
              variant={isSelected ? 'contained' : 'outlined'}
              key={role.id}
              startIcon={<DiscordEmoji id={role.type.icon || '1135300543024205894'} size={24} />}
              endIcon={
                isSelected ? (
                  <ArrowRight />
                ) : isBuildSelected ? null : (
                  <Box sx={{ width: 20, height: 20 }} />
                )
              }
              style={{ justifyContent: isBuildSelected ? 'center' : 'flex-start' }}
              sx={{
                '& .MuiButton-startIcon': {
                  marginLeft: isBuildSelected ? 0 : '-4px',
                  marginRight: isBuildSelected ? 0 : '8px'
                }
              }}
              onClick={() => onRoleSelected(role.id)}
            >
              {isBuildSelected ? '' : role.type.name}
            </Button>
          );
        })}

      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Plus />
        {isBuildSelected ? '' : 'Add Role'}
      </Button>

      <CompModal open={open} setOpen={setOpen}>
        <h2 style={{ color: 'white' }}>Select Role to Add</h2>
        {isLoading ? (
          <Loader />
        ) : (
          <Stack direction={'row'} spacing={2} alignItems="center">
            <div>
              <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="demo-simple-select-filled-label">Select Role</InputLabel>
                <Select
                  value={selectedRoleToAdd || ''}
                  onChange={e => setSelectedRoleToAdd(e.target.value as number)}
                >
                  {rolesToSelectFrom
                    .sort((roleA, roleB) => {
                      return (roleA.order || 9999999) - (roleB.order || 9999999);
                    })
                    .map(role => (
                      <MenuItem value={role.id} key={role.id}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <DiscordEmoji id={role.icon || '1135300543024205894'} size={24} />
                          <span>{role.name}</span>
                        </Stack>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <Button
                variant="contained"
                disabled={!selectedRoleToAdd}
                onClick={async () => {
                  if (selectedRoleToAdd) {
                    await addRole({ comp_id: comp_id, role_id: selectedRoleToAdd }).unwrap();
                    setOpen(false);
                  }
                }}
              >
                Add
              </Button>
            </div>
            <div>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </div>
          </Stack>
        )}
      </CompModal>
    </Stack>
  );
};

const BuildListPane = ({
  selectedRole,
  selectedBuildId,
  onBuildSelected,
  builds,
  comp_id
}: {
  selectedRole: Exclude<CompRole, null | undefined>;
  selectedBuildId: number | null;
  onBuildSelected: (build: number | null, slotSelected: SlotType | null) => void;
  builds: CompBuild[];
  comp_id: number;
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [createBuild, { isLoading: isBuildCreating }] = useCreateBuildMutation();
  const [deleteRole] = useRemoveRoleFromCompMutation();
  const buildsForRole = builds.filter(build => build?.role_id === selectedRole.role_type_id);

  return (
    <Stack
      spacing={2}
      component={Paper}
      sx={{ padding: 2 }}
      alignItems={selectedBuildId === null ? 'flex-start' : 'center'}
    >
      <h2 style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <DiscordEmoji
          id={selectedRole.type?.icon || ''}
          onClick={() => onBuildSelected(null, null)}
        />
        {!selectedBuildId && (
          <>
            <span>{selectedRole.type?.name} Builds</span>
            <Button variant="text" color="warning" onClick={() => setDeleteModalOpen(true)}>
              Delete
            </Button>
          </>
        )}
      </h2>
      <Stack spacing={1}>
        {buildsForRole.map(build => {
          if (!build) return null;

          if (selectedBuildId !== null) {
            return (
              <BuildButton
                key={build.id}
                build={build}
                selected={build.id === selectedBuildId}
                onClick={() => {
                  onBuildSelected(build.id, SlotType.Weapon);
                }}
              />
            );
          }

          return (
            <BuildRow
              key={build.id}
              build={build}
              onClick={slotType => {
                onBuildSelected(build.id, slotType);
              }}
            />
          );
        })}
      </Stack>
      <Button
        variant="contained"
        color="secondary"
        disabled={isBuildCreating || undefined}
        onClick={() => {
          if (!selectedRole.role_type_id) return null;
          createBuild({
            comp_id,
            role_id: selectedRole.role_type_id
          });
        }}
      >
        {isBuildCreating ? (
          <Spinner size="button" />
        ) : (
          <>
            <Plus />
            {selectedBuildId === null && <span>Add Build</span>}
          </>
        )}
      </Button>

      <CompModal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <Stack sx={{ color: 'white' }} alignItems="center" justifyContent="center" spacing={2}>
          <h2>Are you sure you want to delete?</h2>
          <h1 style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DiscordEmoji id={selectedRole.type?.icon || ''} /> {selectedRole.type?.name}
          </h1>
          <p>This will delete all builds associated with this role.</p>
          <p style={{ color: 'red' }}>This cannot be undone.</p>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                if (!selectedRole.role_type_id) return;
                deleteRole({
                  comp_id,
                  role_type_id: selectedRole.role_type_id
                });
              }}
            >
              Delete
            </Button>
            <Button variant="text" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </CompModal>
    </Stack>
  );
};

export const BuildViewPane = ({
  build,
  selectedSlot,
  onSlotSelected
}: {
  build: CompBuild;
  selectedSlot: SlotType | null;
  onSlotSelected: (slot: SlotType) => void;
}) => {
  if (!build) return null;
  const items = build.items || [];

  const slots = [
    [SlotType.Weapon, styles.weaponSlot],
    [SlotType.Offhand, styles.offhandSlot],
    [SlotType.Head, styles.headSlot],
    [SlotType.Chest, styles.armorSlot],
    [SlotType.Feet, styles.legsSlot],
    [SlotType.Cape, styles.capeSlot],
    [SlotType.Food, styles.foodSlot],
    [SlotType.Potion, styles.potionSlot]
  ] as const;

  return (
    <div className={styles.buildView}>
      <div className={styles.gear}>
        {slots.map(([slotType, className]) => (
          <Slot
            key={slotType}
            items={items.filter(item => {
              if (
                slotType === SlotType.Offhand &&
                item?.item?.slot === SlotType.Weapon &&
                item?.item?.two_handed
              ) {
                return true;
              }

              return item?.item?.slot === slotType;
            })}
            slotType={slotType}
            onClick={() => onSlotSelected(slotType)}
            className={className}
            selected={selectedSlot === slotType}
          />
        ))}
      </div>
      <SlotItems
        items={items.filter(item => {
          if (
            selectedSlot === SlotType.Offhand &&
            item?.item?.slot === SlotType.Weapon &&
            item?.item?.two_handed
          ) {
            return true;
          }

          return item?.item?.slot === selectedSlot;
        })}
      />
    </div>
  );
};

const ItemsPane = ({
  slot,
  selectedItemIds
}: {
  slot: SlotType;
  selectedItemIds?: (number | undefined)[];
}) => {
  const { comp_id, selectedBuildId } = React.useContext(CompContext);
  const { data: categories, isFetching } = useGetSlotItemsQuery({ slot });
  const [addItem] = useAddItemToBuildMutation();
  const [removeItem] = useRemoveItemFromBuildMutation();

  if (!categories || isFetching || !comp_id || !selectedBuildId) {
    return <Loader />;
  }

  return (
    <Box>
      {categories.map(category => (
        <div key={category.id}>
          <h1
            style={{
              backgroundColor: 'rgba(0,0,0,0.5)',
              padding: '5px 10px',
              borderRadius: '8px'
            }}
          >
            {category.name}
          </h1>
          <Box sx={{ marginBottom: 5 }}>
            {category.subcategories.map(subcategory => (
              <Box sx={{ marginBottom: 1 }} key={subcategory.id}>
                <h2
                  style={{
                    marginLeft: '5px',
                    marginBottom: '5px',
                    paddingLeft: '10px',
                    borderBottom: '1px solid gray'
                  }}
                >
                  {subcategory.name}
                </h2>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0px',
                    marginLeft: 1
                  }}
                >
                  {subcategory.items.map(item => (
                    <ItemImage
                      key={item.id}
                      item={item}
                      context="item-list"
                      onClick={() => {
                        const numItems = selectedItemIds?.length || 0;

                        if (selectedItemIds?.includes(item.id)) {
                          removeItem({
                            comp_id,
                            build_id: selectedBuildId,
                            item_id: item.id
                          });
                          return;
                        }

                        if (slot === SlotType.Weapon && numItems >= 1) {
                          const currentWeaponId = selectedItemIds?.[0];

                          if (currentWeaponId) {
                            removeItem({
                              comp_id,
                              build_id: selectedBuildId,
                              item_id: currentWeaponId
                            });
                          }

                          addItem({ comp_id, build_id: selectedBuildId, item });
                          return;
                        }

                        if (numItems >= 4) {
                          return;
                        }

                        addItem({ comp_id, build_id: selectedBuildId, item });
                      }}
                      className={clsx(styles.itemsListItem, {
                        [styles.selected]: selectedItemIds?.includes(item.id)
                      })}
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </div>
      ))}
    </Box>
  );
};

const BuildButton = ({
  build,
  onClick,
  selected
}: {
  build: Exclude<CompBuild, null>;
  onClick: () => void;
  selected?: boolean;
}) => {
  const items = build.items || [];

  return (
    <div
      className={styles.buildButton}
      style={{
        borderRadius: '8px',
        backgroundColor: selected ? 'lightblue' : 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Slot
        items={items.filter(item => item?.item?.slot === SlotType.Weapon)}
        slotType={SlotType.Weapon}
        onClick={onClick}
        context="buildList"
      />
    </div>
  );
};

export const BuildRow = ({
  build,
  onClick
}: {
  build: Exclude<CompBuild, null>;
  onClick: (slotType: SlotType) => void;
}) => {
  const [deleteBuild] = useDeleteBuildMutation();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const items = build.items || [];

  const slots = [
    SlotType.Weapon,
    SlotType.Offhand,
    SlotType.Head,
    SlotType.Chest,
    SlotType.Feet,
    SlotType.Cape,
    SlotType.Food,
    SlotType.Potion
  ] as const;

  return (
    <Stack spacing={1} direction="row">
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {slots.map(slotType => (
          <Slot
            key={slotType}
            items={items.filter(item => {
              if (
                slotType === SlotType.Offhand &&
                item?.item?.slot === SlotType.Weapon &&
                item?.item?.two_handed
              ) {
                return true;
              }

              return item?.item?.slot === slotType;
            })}
            slotType={slotType}
            onClick={() => onClick(slotType)}
            context="buildList"
          />
        ))}
      </Box>
      <Stack>
        <DeleteButton onClick={() => setDeleteModalOpen(true)} />
      </Stack>
      <CompModal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <Stack sx={{ color: 'white' }} alignItems="center" justifyContent="center" spacing={2}>
          <h2>Are you sure you want to delete?</h2>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {slots.map(slotType => (
              <Slot
                key={slotType}
                items={items.filter(item => {
                  if (
                    slotType === SlotType.Offhand &&
                    item?.item?.slot === SlotType.Weapon &&
                    item?.item?.two_handed
                  ) {
                    return true;
                  }

                  return item?.item?.slot === slotType;
                })}
                slotType={slotType}
                onClick={() => {}}
                context="deleteList"
              />
            ))}
          </Box>
          <p>This cannot be undone.</p>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={async () => {
                await deleteBuild({ comp_id: build.comp_id, build_id: build.id });
                setDeleteModalOpen(false);
              }}
            >
              Delete
            </Button>
            <Button variant="text" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </CompModal>
    </Stack>
  );
};

const Slot = ({
  items,
  slotType,
  onClick,
  context = 'build',
  className,
  selected
}: {
  items: CompBuildItem[];
  slotType: SlotType;
  onClick: () => void;
  context?: 'buildList' | 'build' | 'deleteList';
  className?: string;
  selected?: boolean;
}) => {
  const isBuildList = context === 'buildList';
  const isDeleteList = context === 'deleteList';
  const is2Handed = slotType === SlotType.Offhand && items.some(item => item?.item?.two_handed);

  return (
    <div
      className={clsx(styles.slot, className, {
        [styles.deleteListSlot]: isDeleteList,
        [styles.buildListSlot]: isBuildList,
        [styles.slotSelected]: selected
      })}
    >
      <ItemImageContainer
        count={items.length}
        onClick={() => {
          if (is2Handed) {
            return;
          }
          onClick();
        }}
        className={clsx({ [styles.twoHanded]: is2Handed })}
      >
        {items.length > 0 ? (
          items.map(item => {
            if (!item) return null;
            return (
              <ItemImage
                key={item.item?.id}
                item={{
                  id: item.item?.id || 0,
                  icon: item.item?.icon || null,
                  name: item.item?.name || null
                }}
                context={context + slotType}
              />
            );
          })
        ) : isBuildList || isDeleteList ? (
          <Placeholder onClick={onClick} />
        ) : null}
      </ItemImageContainer>
    </div>
  );
};

const Placeholder = ({ onClick }: { onClick: () => void }) => {
  return <div className={styles.placeholder} onClick={onClick} />;
};

const DeleteButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button onClick={onClick} className={styles.deleteButton}>
      <Delete sx={{ fontSize: 16 }} />
    </button>
  );
};

export const ItemImageContainer = ({
  children,
  count,
  className,
  onClick
}: {
  children: React.ReactNode;
  count: number;
  className?: string;
  onClick: () => void;
}) => {
  return (
    <div
      className={clsx(
        styles.itemContainer,
        {
          [styles.items2]: count === 2,
          [styles.items3plus]: count >= 3
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const ItemImage = ({
  item,
  onClick,
  className,
  context
}: {
  item: {
    id: number;
    icon?: string | null;
    name?: string | null;
  } | null;
  onClick?: () => void;
  className?: string;
  context: string;
}) => (
  <div className={clsx(styles.item, className)} onClick={onClick}>
    <img
      className={styles.itemImage}
      src={`https://iqwgqnbvoejzaqceivdm.supabase.co/storage/v1/object/public/icons/${item?.icon}`}
      alt={item?.name || ''}
      data-tooltip-id={context + item?.id?.toString()}
      data-tooltip-content={item?.name}
    />
    <Tooltip id={context + item?.id?.toString()} variant="light" style={{ zIndex: 1000 }} />
  </div>
);

const SlotItems = ({ items }: { items: CompBuildItem[] }) => {
  return (
    <Stack spacing={2} alignItems="center">
      {items.map(item => (
        <div className={styles.slotItem} key={item.id}>
          <Stack direction="row" spacing={1} alignItems="center">
            <ItemImage
              item={{ id: item.id, icon: item.item?.icon, name: item.item?.name }}
              className={styles.spellsItemIcon}
              context="spellImage"
            />
            <h3>{item.item?.name}</h3>
          </Stack>

          <ItemSpells item={item} />
        </div>
      ))}
    </Stack>
  );
};

const ItemSpells = ({ item }: { item: CompBuildItem }) => {
  const { comp_id } = useContext(CompContext);
  const [toggleItemSpell] = useToggleItemSpellMutation();
  const spells = item.item?.available_spells || [];

  return (
    <div className={styles.slotSpells}>
      {['q', 'w', 'e', 'r', 'd', 'f', 'passive 1', 'passive 2'].map(slot => {
        const spellList = spells.filter(spell => spell.spell?.slot === slot);
        if (spellList.length === 0) return null;
        return (
          <SpellList
            key={slot}
            spellSlot={slot}
            spells={spellList}
            selectedSpells={(item.selected_spells || []).map(spell => spell?.spell?.id)}
            onClick={(slot, spell) => {
              if (!spell.spell || !comp_id || !item.build_id) return;
              toggleItemSpell({
                slot,
                comp_id: comp_id,
                build_id: item.build_id,
                item_id: item.id,
                spell_id: spell.spell.id
              });
            }}
          />
        );
      })}
    </div>
  );
};

const SpellList = ({
  spellSlot,
  spells,
  onClick,
  selectedSpells
}: {
  spellSlot: string;
  spells: (Albion_Item_Spell & AlbionSpell)[];
  onClick: (spellSlot: string, spell: Exclude<AlbionSpell, null>) => void;
  selectedSpells: (number | undefined)[];
}) => {
  return (
    <div className={styles.spellList}>
      <h1>{spellSlot.replace(/passive \d/, 'p')}</h1>
      <div>
        {spells
          .sort((a, b) => {
            // const aOrder =
            //   a.spell?.order === null || typeof a.spell?.order === 'undefined'
            //     ? 9999
            //     : a.spell?.order;
            // const bOrder =
            //   b.spell?.order === null || typeof b.spell?.order === 'undefined'
            //     ? 9999
            //     : b.spell?.order;
            // return aOrder - bOrder;
            return a.id - b.id;
          })
          .map(spell => (
            <React.Fragment key={spell.spell?.id}>
              <img
                src={`https://iqwgqnbvoejzaqceivdm.supabase.co/storage/v1/object/public/icons/${spell.spell?.icon}`}
                alt={spell.spell?.name}
                className={clsx(styles.spellIcon, {
                  [styles.selectedSpell]: selectedSpells.includes(spell?.spell?.id)
                })}
                onClick={() => {
                  if (!spell.spell) return;
                  onClick(spellSlot, spell);
                }}
                data-tooltip-id={spell?.spell?.id?.toString()}
                data-tooltip-content={spell?.spell?.name}
              />
              <Tooltip id={spell?.spell?.id?.toString()} variant="light" style={{ zIndex: 1000 }} />
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

const CompModal = ({
  children,
  open,
  setOpen
}: {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};
