import React from 'react';
import { Box, Modal } from '@mui/material';

export const SimpleModal = ({
  children,
  open,
  width,
  onClose
}: {
  children: React.ReactNode;
  open: boolean;
  width?: number;
  onClose: () => void;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width,
          border: '1px solid #000',
          boxShadow: 24
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};
