import {
  Comp_Build,
  Comp_Build_Item,
  Comp,
  User,
  Comp_Role_Type,
  Comp_Role,
  Albion_Item,
  Albion_Spell,
  Albion_Item_Category,
  Albion_Item_Subcategory,
  Albion_Item_Spell
} from '../database/database';

export type CompListComp = Pick<Comp, 'id' | 'name' | 'published'> & {
  user: Pick<User, 'id' | 'albion_name'> | null;
};
export type CompList = CompListComp[];

// export type AlbionSpell = { spell: Albion_Spell | null };

// export type AvailableSpell = AlbionSpell | null;

// export type CompBuildItemItem = (Albion_Item & { available_spells: AvailableSpell[] }) | null;

// export type BuildItemSpell = (Comp_Build_Item_Spell & AlbionSpell) | null;

// export type BuildItemSelectedSpell = { spell: AlbionSpell } | null;

// export type CompBuildItem =
//   | (Comp_Build_Item & { item: Albion_Item | null } & {
//       selected_spells: BuildItemSelectedSpell[] | null;
//     })
//   | null;

// export type CompBuild = (Comp_Build & { items: CompBuildItem[] | null }) | null;

// export type CompRole = (Comp_Role & { type: Comp_Role_Type | null }) | null;

// export type CompResponse =
//   | (Comp & {
//       builds: CompBuild[] | null;
//       roles: CompRole[] | null;
//     });

export type AlbionSpell = {
  spell: Albion_Spell | null;
};

export type AlbionItem = Albion_Item & {
  available_spells: (Albion_Item_Spell & AlbionSpell)[];
};

export type CompBuildItem = Comp_Build_Item & {
  item: AlbionItem | null;
  selected_spells: AlbionSpell[] | null;
};

export type CompBuild = Comp_Build & {
  items: CompBuildItem[];
};

export type CompRole = Comp_Role & {
  type: Comp_Role_Type | null;
};

export type CompResponse = Comp & {
  builds: CompBuild[] | null;
  roles: CompRole[];
};

export type AlbionItemSubcategory = Albion_Item_Subcategory & {
  category: Albion_Item_Category | null;
};

export type ItemsResponse = AlbionItem & {
  subcategory: AlbionItemSubcategory | null;
};

export enum SlotType {
  Weapon = 'weapon',
  Offhand = 'offhand',
  Head = 'head',
  Chest = 'chest',
  Feet = 'feet',
  Cape = 'cape',
  Food = 'food',
  Potion = 'potion'
}
