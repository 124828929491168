import { Form, Title, SaveButton } from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Button
} from '@mui/material';
import { DiscordEmoji } from '../../../../../components/discord-emoji/DiscordEmoji';
import React, { useEffect } from 'react';
import { useController, useWatch, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { Spinner } from '../../../../../components/spinner/Spinner';
import { RouterOutputs, trpc } from '../../../api/trpc';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '../../../../../components/loader/Loader';
import Close from '@mui/icons-material/Close';

export const EventRegistration = ({ event_id }: { event_id: number }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const utils = trpc.useUtils();
  const { data: event, isPending, isError } = trpc.events.getEvent.useQuery({ event_id });
  const {
    data: currentData,
    isPending: currentDataPending,
    isError: currentDataError
  } = trpc.events.getPlayerEventRegistration.useQuery({ event_id });
  const register = trpc.events.registerForEvent.useMutation({
    onSuccess: () => {
      utils.events.getEvent.invalidate({ event_id });
      utils.events.getParticipants.invalidate({ event_id });
    }
  });
  const removeRegistration = trpc.events.removePlayerEventRegistration.useMutation({
    onSuccess: () => {
      utils.events.getEvent.invalidate({ event_id });
      utils.events.getParticipants.invalidate({ event_id });
    }
  });

  if (isPending || currentDataPending) {
    return <Loader />;
  }

  if (isError || currentDataError) {
    return <div>There was an error loading the registration data.</div>;
  }

  const will_attend = currentData ? (currentData.tentative ? 'no' : 'yes') : '';
  const late = currentData ? (currentData.late ? 'yes' : 'no') : '';

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', maxWidth: '400px' }}>
      <Title title="Event Registration" />
      <Box sx={{ width: '100%' }}>
        <Form
          onSubmit={async data => {
            setIsSubmitting(true);
            await register.mutateAsync({
              event_id,
              will_attend: data.will_attend,
              late: data.late,
              role_id: data.role_id ? parseInt(data.role_id) : undefined,
              build_id: data.build_id ? parseInt(data.build_id) : undefined
            });
            setIsSubmitting(false);
            searchParams.delete('register');
            setSearchParams(searchParams);
          }}
          defaultValues={{
            will_attend,
            late,
            role_id: currentData ? currentData.role_id : '',
            build_id: currentData ? currentData.build_id : ''
          }}
        >
          <Card>
            {event.image_url && (
              <CardMedia component="img" height="140" image={event.image_url} alt="Event Image" />
            )}
            <CardContent>
              <h2>Event Registration</h2>
              <h3 style={{ marginBottom: '10px', color: '#cccccc' }}>{event.name}</h3>
              <p style={{ margin: 0, color: '#aaaaaa', padding: 0, fontSize: '0.9em' }}>
                {dayjs(event.start_time).utc(true).local().format('LLLL')}
              </p>
              <hr style={{ margin: '20px 0' }} />
              <Stack spacing={1}>
                <YesNoInput name="will_attend" label="Are you sure you can attend?" />
                <YesNoInput name="late" label="Will you be late?" />
                {event.roles.length > 0 && <RoleSelector roles={event.roles} />}
                {event.builds.length > 0 && <BuildSelector builds={event.builds} />}
                {isSubmitting ? (
                  <Button disabled={true}>
                    <Spinner />
                  </Button>
                ) : (
                  <RegisterButton
                    ignoreRoles={event.roles.length === 0}
                    ignoreBuilds={event.builds.length === 0}
                    registrationClosed={!!event.started_time}
                  />
                )}
                {currentData && (
                  <Button
                    disabled={isSubmitting || !!event.started_time}
                    startIcon={<Close />}
                    onClick={async () => {
                      setIsSubmitting(true);
                      await removeRegistration.mutateAsync({ event_id });
                      setIsSubmitting(false);
                      searchParams.delete('register');
                      setSearchParams(searchParams);
                    }}
                  >
                    Remove Registration
                  </Button>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Form>
      </Box>
    </Box>
  );
};

const RoleSelector = ({ roles }: { roles: RouterOutputs['events']['getEvent']['roles'] }) => {
  const roleSelection = useController({
    name: 'role_id',
    defaultValue: '',
    rules: { required: 'Role is required' }
  });

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="demo-simple-select-filled-label">Select Role</InputLabel>
        <Select {...roleSelection.field}>
          {[...roles]
            .sort((roleA: any, roleB: any) => {
              return (roleA.order || 9999999) - (roleB.order || 9999999);
            })
            .map((role: any) => (
              <MenuItem value={role.id} key={role.id}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <DiscordEmoji id={role.icon || '1135300543024205894'} size={24} />
                  <span>{role.name}</span>
                </Stack>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

const BuildSelector = ({ builds }: { builds: RouterOutputs['events']['getEvent']['builds'] }) => {
  const { setValue } = useFormContext();
  const role = useWatch({ name: 'role_id' });
  const input = useController({
    name: 'build_id',
    defaultValue: '',
    rules: { required: 'Role is required' }
  });

  useEffect(() => {
    setValue('build_id', '', { shouldValidate: true });
  }, [role, setValue]);

  if (!role) {
    return null;
  }

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="demo-simple-select-filled-label">Select Build</InputLabel>
        <Select {...input.field}>
          {[...builds]
            .filter(build => build.role_id === role)
            .map(build => (
              <MenuItem value={build.id} key={build.id}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <img src={build.icon} style={{ width: '24px', height: '24px' }} alt="" />
                  <span>{build.weaponName}</span>
                </Stack>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

const YesNoInput = ({ label, name }: { label: string; name: string }) => {
  const input = useController({
    name,
    rules: { required: 'Required' }
  });

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <Stack spacing={2} direction="row" alignItems="center">
          <span>{label}</span>
          <ToggleButtonGroup
            size="small"
            color="primary"
            exclusive
            aria-label="On Time"
            {...input.field}
          >
            <ToggleButton value="yes">Yes</ToggleButton>
            <ToggleButton value="no">No</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </FormControl>
    </div>
  );
};

const RegisterButton = ({
  ignoreRoles,
  ignoreBuilds,
  registrationClosed
}: {
  ignoreRoles: boolean;
  ignoreBuilds: boolean;
  registrationClosed: boolean;
}) => {
  const role = useWatch({ name: 'role_id' });
  const build = useWatch({ name: 'build_id' });
  const late = useWatch({ name: 'late' });
  const will_attend = useWatch({ name: 'will_attend' });

  if (registrationClosed) {
    return <SaveButton label="Registration Closed" disabled={true} />;
  }

  const enable = (role || ignoreRoles) && (build || ignoreBuilds) && late && will_attend;
  return <SaveButton label="Register" disabled={enable ? undefined : true} />;
};
