import { Box } from '@mui/material';

export const ContentBox = ({
  children,
  padding = 1,
  className
}: {
  children: React.ReactNode;
  padding?: number;
  className?: string;
}) => {
  return (
    <Box sx={{ minHeight: '100%', padding, containerType: 'inline-size' }} className={className}>
      {children}
    </Box>
  );
};
