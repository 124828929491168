import { ContentBox } from '../../../components/ContentBox';
import { Title } from 'react-admin';
import { Card } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../components/loader/Loader';
import { trpc } from '../../../api/trpc';
import { EventForm } from '../components/EventForm';

export const EditEvent = () => {
  const { id } = useParams() as { id: string };
  const event_id = parseInt(id, 10);
  const { data: eventData, isPending, isError } = trpc.events.getEvent.useQuery({ event_id });

  if (isPending) {
    return <Loader />;
  }

  if (isError) {
    return <ContentBox padding={0}>Error loading event.</ContentBox>;
  }

  return (
    <ContentBox>
      <Title title="Edit Event" />
      <h1 style={{ marginLeft: '10px' }}>Edit Event</h1>
      <Card>
        <EventForm eventData={eventData} />
      </Card>
    </ContentBox>
  );
};
