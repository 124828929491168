import * as React from 'react';
import {
  List,
  Datagrid,
  Edit,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  useRecordContext,
  ImageField,
  BooleanField
} from 'react-admin';
import Person from '@mui/icons-material/Person';
import styles from './Members.module.less';
import { Avatar } from '@mui/material';
export const MemberIcon = Person;

type Member = {
  id: number;
  name: string;
  isMember: boolean;
  avatar: string;
};

export const MemberList = () => (
  <List>
    <Datagrid>
      <ListAvatar size={32} />
      <TextField source="name" />
      <BooleanField source="isMember" />
      <EditButton />
    </Datagrid>
  </List>
);

const MemberName = () => {
  const record = useRecordContext();
  return <span>{record ? `"${record.albion_name}"` : ''}</span>;
};

export const MemberEdit = () => (
  <Edit title={<MemberName />}>
    <SimpleForm>
      <TextField disabled source="id" />
      <TextInput name="ablion_name" source="albion_name" />
    </SimpleForm>
  </Edit>
);

const ListAvatar = ({ size }: { size: number }) => {
  const record = useRecordContext<Member>();

  return (
    <Avatar
      src={record.avatar}
      style={{
        width: size,
        height: size
      }}
    />
  );
};
