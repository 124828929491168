import EventAvailable from '@mui/icons-material/EventAvailable';
import { ContentBox } from '../../../components/ContentBox';
import { useParams } from 'react-router-dom';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Stop from '@mui/icons-material/Stop';
import Replay from '@mui/icons-material/Replay';
import styles from './RunEvent.module.less';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useImperativeHandle, useRef, useState } from 'react';
import Clear from '@mui/icons-material/Clear';
import { v4 as uuid } from 'uuid';
import { InputNumberFormat } from '@react-input/number-format';
import { strToNum } from '../../../../../../server/utils';
import { Autocomplete } from '@mui/lab';
import { trpc } from '../../../api/trpc';
import { RouterOutputs } from '../../../../../../server/AppRouter';
import { produce } from 'immer';
import { useDebouncedCallback } from 'use-debounce';
import { EventRunningBanner } from '../../../components/event-running-banner/EventRunningBanner';

dayjs.extend(duration);

export const RunEvent = () => {
  const { id } = useParams() as { id: string };
  const event_id = parseInt(id, 10);

  const utils = trpc.useUtils();
  const { data: event } = trpc.events.getEvent.useQuery({ event_id });
  const { data: memberOptions } = trpc.members.get.useQuery();
  const { data: members } = trpc.events.getParticipants.useQuery(
    { event_id },
    {
      refetchInterval: event?.started_time && !event?.ended_time ? 3000 : false
    }
  );
  const { data: eventSilver } = trpc.events.getEventSilver.useQuery({ event_id });
  const { data: timeInVc } = trpc.events.getMemberTimeInVc.useQuery(
    { event_id },
    {
      refetchInterval: event?.started_time && !event?.ended_time ? 3000 : false
    }
  );
  const { mutateAsync: startEvent } = trpc.events.start.useMutation({
    onMutate: () => {
      utils.events.getEvent.setData({ event_id }, old => {
        if (!old) return;
        return {
          ...old,
          started_time: old.started_time ?? dayjs().utc().toISOString(),
          ended_time: null
        };
      });
    },
    onSuccess: () => {
      void utils.events.getEvent.invalidate({ event_id });
    }
  });
  const { mutateAsync: endEvent } = trpc.events.end.useMutation({
    onMutate: () => {
      utils.events.getEvent.setData({ event_id }, old => {
        if (!old) return;
        return {
          ...old,
          ended_time: dayjs().utc().toISOString()
        };
      });
    },
    onSuccess: () => {
      void utils.events.getEvent.invalidate({ event_id });
    }
  });
  const { mutateAsync: addPlayer } = trpc.events.addPlayerManually.useMutation({
    onSuccess: () => {
      utils.events.getEvent.invalidate({ event_id });
      utils.events.getParticipants.invalidate({ event_id });
    }
  });

  const [salePercentage, setSalePercentage] = useState<string>('7');
  const [guildCutPercentage, setGuildCutPercentage] = useState<string>('0');
  const [bookSalePrice, setBookSalePrice] = useState<string>('7,000');
  const [playerToAdd, setPlayerToAdd] = useState<{
    id: number;
    label: string;
  } | null>(null);

  const updateJuice = trpc.events.updateEventSilver.useMutation({
    onMutate: async data => {
      await utils.events.getEventSilver.cancel({ event_id });
      const previousData = utils.events.getEventSilver.getData({ event_id });

      utils.events.getEventSilver.setData({ event_id }, old => {
        return produce(old, draft => {
          if (!draft) return;
          const index = draft.findIndex(total => total.id === data.id);
          if (index === -1) {
            draft.push({
              id: data.id,
              amount: data.amount,
              type: 'juice',
              event_id,
              auto_id: 9999999999
            });
          } else {
            draft[index] = { ...draft[index], amount: data.amount };
          }
        });
      });

      return { previousData };
    },

    onSuccess: () => {
      void utils.events.getEventSilver.invalidate({ event_id });
    }
  });

  if (!event || !members || !timeInVc || !eventSilver) {
    return <div>Loading...</div>;
  }

  const numPlayers = members.filter(member => member.attended).length;
  const sortedMembers = members.sort((a, b) => {
    if (a.attended && !b.attended) return -1;
    if (!a.attended && b.attended) return 1;
    return a.name.localeCompare(b.name);
  });

  const chestTotals = eventSilver.filter(total => total.type === 'chest');
  const repairTotals = eventSilver.filter(total => total.type === 'repair');
  const juice = eventSilver
    .filter(total => total.type === 'juice')
    .reduce((acc, curr) => acc + curr.amount, 0);

  const chestTotal = chestTotals
    .map(total => (total.type === 'chest' ? total.amount : 0))
    .reduce((acc, curr) => acc + curr, 0);

  const repairCosts = repairTotals
    .map(total => (total.type === 'repair' ? total.amount : 0))
    .reduce((acc, curr) => acc + curr, 0);

  const silverBagsTotal = members.reduce((acc, curr) => acc + curr.silver_bags, 0);

  const booksTotal =
    members.reduce((acc, curr) => acc + (curr.books || 0), 0) * strToNum(bookSalePrice);

  const chestTotalAfterRepairs = chestTotal - repairCosts;

  const saleAmount = Math.floor(chestTotalAfterRepairs * (strToNum(salePercentage) / 100));
  const afterSale = chestTotalAfterRepairs - saleAmount;

  const silverTotal = afterSale + silverBagsTotal + booksTotal;

  const guildCut = Math.floor(silverTotal * (strToNum(guildCutPercentage) / 100));
  const afterGuildCut = silverTotal - guildCut;

  const total = afterGuildCut + juice;
  const perPlayer = Math.floor(total / numPlayers);

  const donatedToGuild =
    guildCut +
    members.reduce((acc, curr) => acc + (curr.split_opted_out && curr.attended ? perPlayer : 0), 0);

  return (
    <ContentBox padding={1}>
      {event.started_time && !event.ended_time && <EventRunningBanner />}
      <div className={styles.topBar}>
        <div className={styles.left}>
          <h1 style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <EventAvailable sx={{ fontSize: 40 }} /> {event.name}
          </h1>
          {!event.started_time && (
            <button onClick={() => startEvent({ event_id })} className={styles.controlButton}>
              <PlayArrow fontSize="small" />
              Start Event
            </button>
          )}
          {event.started_time && !event.ended_time && (
            <button onClick={() => endEvent({ event_id })} className={styles.endEventButton}>
              <Stop fontSize="small" />
              End Event
            </button>
          )}
          {event.started_time && event.ended_time && (
            <button onClick={() => startEvent({ event_id })} className={styles.controlButton}>
              <Replay fontSize="small" />
              Continue Event
            </button>
          )}

          <table>
            <tbody>
              <tr>
                <td>
                  <Typography variant="body2">Event Started:</Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {dayjs(event.started_time).utc(true).local().format('hh:mm:ss a')}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="body2">Event Ended:</Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {event.ended_time &&
                      dayjs(event.ended_time).utc(true).local().format('hh:mm:ss a')}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Stack direction="row" spacing={2} alignItems="flex-start">
        <TableContainer
          component={Paper}
          sx={{ maxWidth: 'fit-content', padding: '10px', paddingTop: '2px', borderRadius: '10px' }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <h4>Inc?</h4>
                </TableCell>
                <TableCell colSpan={2}>
                  <h4>Member</h4>
                </TableCell>
                <TableCell>
                  <h4>Silver Bags</h4>
                </TableCell>
                <TableCell align="center">
                  <h4>Books</h4>
                </TableCell>
                <TableCell align="center">
                  <h4>Opt Out</h4>
                </TableCell>
                <TableCell>
                  <h4>To Pay</h4>
                </TableCell>
                <TableCell align="center">
                  <h4>Paid</h4>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedMembers.map(member => (
                <MemberRow
                  key={member.user.id}
                  member={member}
                  timeInVc={timeInVc[member.user.id] || 0}
                  attended={
                    member.attended_override ??
                    (event.required_participation_time
                      ? dayjs.duration(timeInVc[member.user.id] || 0, 'milliseconds').asMinutes() >
                        event.required_participation_time
                      : true)
                  }
                  toPay={
                    member.attended
                      ? perPlayer -
                        member.silver_bags -
                        (member.books || 0) * strToNum(bookSalePrice)
                      : 0
                  }
                />
              ))}
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <Autocomplete
                      disablePortal
                      renderInput={params => (
                        <TextField {...params} label="Add Player" size="small" />
                      )}
                      options={
                        memberOptions
                          ?.map(member => ({
                            id: member.id,
                            label: member.albion_name?.replace(/!?\??\[AG]\s+/, '') || ''
                          }))
                          .filter(
                            memberOption =>
                              !members.find(member => member?.user?.id === memberOption.id)
                          ) || []
                      }
                      value={playerToAdd}
                      isOptionEqualToValue={(o, v) => o.id === v?.id}
                      onChange={(e, v) => setPlayerToAdd(v)}
                      sx={{ width: 300 }}
                      size="small"
                    />
                    <Button
                      variant="text"
                      size="small"
                      disabled={!playerToAdd}
                      onClick={async () => {
                        if (playerToAdd) {
                          setPlayerToAdd(null);

                          await addPlayer({
                            player_id: playerToAdd.id,
                            event_id
                          });
                        }
                      }}
                    >
                      Add Player
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          sx={{ maxWidth: 'fit-content', padding: '10px', paddingTop: '2px', borderRadius: '10px' }}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <h2>Loot Split</h2>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <SummedTotalsTable
                type="chest"
                totals={chestTotals}
                rowLabel="Amount in Chest"
                buttonLabel="Add Chest"
              />
              <SummedTotalsTable
                type="repair"
                totals={repairTotals}
                isNegative={true}
                rowLabel="Repair Cost"
                buttonLabel="Add Repair Cost"
              />
              <Tally
                numPlayers={numPlayers}
                chestTotal={chestTotalAfterRepairs}
                saleAmount={saleAmount}
                silverBagsTotal={silverBagsTotal}
                booksTotal={booksTotal}
                salePercentage={strToNum(salePercentage)}
                guildCutPercentage={strToNum(guildCutPercentage)}
                guildCut={guildCut}
                juice={juice}
                setJuice={value => {
                  updateJuice.mutate({
                    id: `${event_id}-juice`,
                    amount: strToNum(value),
                    event_id,
                    type: 'juice'
                  });
                }}
                totalSplit={total}
                perPlayer={perPlayer}
                donatedToGuild={donatedToGuild}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </ContentBox>
  );
};

const MemberRow = ({
  member,
  attended,
  timeInVc,
  toPay
}: {
  member: RouterOutputs['events']['getParticipants']['0'];
  attended: boolean;
  timeInVc: number;
  toPay: number;
}) => {
  const [silverBags, setSilverBags] = useState<string>(
    new Intl.NumberFormat(['en-US']).format(member.silver_bags)
  );
  const utils = trpc.useUtils();
  const queryData = { event_id: member.event_id };

  const mutation = trpc.events.updateParticipant.useMutation({
    onMutate: async data => {
      await utils.events.getParticipants.cancel(queryData);
      const previousData = utils.events.getParticipants.getData(queryData);

      utils.events.getParticipants.setData(queryData, old => {
        return produce(old, draft => {
          if (!draft) return;
          const index = draft.findIndex(participant => participant.user_id === data.user_id);
          draft[index] = { ...draft[index], ...data };
        });
      });

      return { previousData };
    },

    onSettled: () => {
      void utils.events.getParticipants.invalidate(queryData);
    }
  });

  const debouncedMutate = useDebouncedCallback(mutation.mutate, 500);

  return (
    <TableRow
      key={member.user.id}
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)'
        }
      }}
    >
      <TableCell align="center">
        {/* Included */}
        <Checkbox
          sx={{ padding: 0 }}
          checked={attended}
          onChange={e => {
            mutation.mutate({
              user_id: member.user.id,
              event_id: member.event_id,
              attended_override: e.target.checked
            });
          }}
        />
      </TableCell>
      <TableCell sx={{ paddingRight: 0 }}>
        <Avatar
          src={member.user.displayAvatarURL}
          alt={member.name}
          sx={{ width: 24, height: 24 }}
        />
      </TableCell>
      <TableCell>
        <p>{member.name}</p>
        <p
          style={{
            fontSize: '0.8em',
            color: '#aaaaaa'
          }}
        >
          {dayjs.duration(timeInVc, 'milliseconds').format('HH:mm:ss')}
        </p>
      </TableCell>
      <TableCell>
        {/* Silver Bags */}
        <SilverInputField
          id={member.user.id.toString()}
          amount={silverBags}
          onChange={(_, amount) => {
            setSilverBags(amount);

            utils.events.getParticipants.setData(queryData, old => {
              return produce(old, draft => {
                if (!draft) return;
                const index = draft.findIndex(
                  participant => participant.user_id === member.user_id
                );
                draft[index] = { ...draft[index], silver_bags: strToNum(amount) };
              });
            });

            debouncedMutate({
              user_id: member.user.id,
              event_id: member.event_id,
              silver_bags: strToNum(amount)
            });
          }}
        />
      </TableCell>
      <TableCell align="center">
        {/* Books */}
        <input
          type="text"
          style={{ width: '30px' }}
          value={member.books || ''}
          onChange={e => {
            mutation.mutate({
              user_id: member.user.id,
              event_id: member.event_id,
              books: parseInt(e.target.value, 10) || 0
            });
          }}
        />
      </TableCell>
      <TableCell align="center">
        {/* Opt Out */}
        <Checkbox
          sx={{ padding: 0 }}
          checked={member.split_opted_out}
          disabled={member.split_paid || !member.attended}
          onChange={e => {
            mutation.mutate({
              user_id: member.user.id,
              event_id: member.event_id,
              split_opted_out: e.target.checked
            });
          }}
        />
      </TableCell>
      <TableCell>
        {/* To Pay */}
        <SilverAmount amount={toPay} />
      </TableCell>
      <TableCell align="center">
        {/* Paid */}
        <Checkbox
          sx={{ padding: 0 }}
          checked={member.split_paid}
          disabled={member.split_opted_out || !member.attended}
          onChange={e => {
            mutation.mutate({
              user_id: member.user.id,
              event_id: member.event_id,
              split_paid: e.target.checked
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

type ChestTotal = { id: string; amount: number; type: string; event_id: number };
const SummedTotalsTable = ({
  type,
  totals,
  rowLabel,
  buttonLabel,
  isNegative
}: {
  type: string;
  totals: ChestTotal[];
  rowLabel: string;
  buttonLabel: string;
  isNegative?: boolean;
}) => {
  const utils = trpc.useUtils();
  const { id } = useParams() as { id: string };
  const event_id = parseInt(id, 10);
  const newInputRef = useRef<{ focusNewRow: () => void }>(null);

  const updateSilver = trpc.events.updateEventSilver.useMutation({
    onMutate: async data => {
      await utils.events.getEventSilver.cancel({ event_id });
      const previousData = utils.events.getEventSilver.getData({ event_id });

      utils.events.getEventSilver.setData({ event_id }, old => {
        return produce(old, draft => {
          if (!draft) return;
          const index = draft.findIndex(total => total.id === data.id);
          if (index === -1) {
            draft.push({
              id: data.id,
              amount: data.amount,
              type,
              event_id,
              auto_id: 9999999999
            });
          } else {
            draft[index].amount = data.amount;
          }
        });
      });

      return { previousData };
    },

    onSuccess: () => {
      void utils.events.getEventSilver.invalidate({ event_id });
    }
  });

  const deleteSilver = trpc.events.deleteEventSilver.useMutation({
    onMutate: async data => {
      await utils.events.getEventSilver.cancel({ event_id });
      const previousData = utils.events.getEventSilver.getData({ event_id });

      utils.events.getEventSilver.setData({ event_id }, old => {
        return produce(old, draft => {
          if (!draft) return;
          return draft.filter(total => total.id !== data.id);
        });
      });

      return { previousData };
    },

    onSuccess: () => {
      void utils.events.getEventSilver.invalidate({ event_id });
    }
  });

  const handleAmountChange = (id: string, value: string) => {
    updateSilver.mutate({ id, amount: strToNum(value), event_id, type });
  };

  const handleDeleteRow = (id: string) => {
    deleteSilver.mutate({ id });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
    addNewRow();
  };

  const addNewRow = () => {
    updateSilver.mutate({ id: uuid(), amount: 0, event_id, type });
    setTimeout(() => {
      if (newInputRef.current) {
        newInputRef.current.focusNewRow();
      }
    }, 0);
  };

  return (
    <>
      {totals.map((row, i) => (
        <TableRow key={row.id}>
          <TableCell sx={{ minWidth: 'fit-content' }}>
            {rowLabel} {i + 1}
          </TableCell>
          <TableCell>
            <SilverInputField
              id={row.id}
              amount={row.amount}
              onChange={handleAmountChange}
              onKeyPress={handleKeyPress}
              onDelete={handleDeleteRow}
              ref={i === totals.length - 1 ? newInputRef : null}
              isNegative={isNegative}
            />
          </TableCell>
        </TableRow>
      ))}
      <TableRow>
        <TableCell colSpan={2} align="right" sx={{ padding: 0, paddingBottom: '3px' }}>
          <Button
            variant="text"
            size="small"
            sx={{ fontSize: '0.7em', padding: '2px' }}
            onClick={addNewRow}
          >
            {buttonLabel}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const SilverInputField = React.forwardRef<
  { focusNewRow: () => void },
  {
    id: string;
    amount: number | string;
    onChange?: (id: string, value: string) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    onDelete?: (id: string) => void;
    isNegative?: boolean;
  }
>(({ id, amount, onChange, onKeyPress, onDelete, isNegative }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focusNewRow: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }));

  function clear(): void {
    if (inputRef.current) {
      inputRef.current.value = '0';
    }
    onDelete?.(id);
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <SilverIcon style={{ marginRight: '1px' }} />
      {isNegative && '-'}
      <InputNumberFormat
        defaultValue={amount}
        locales={['en-US']}
        onChange={e => onChange?.(id, e.target.value)}
        onKeyUp={onKeyPress}
        ref={inputRef}
        className={styles.silverInput}
      />

      {onDelete && (
        <Clear
          sx={{
            fontSize: '1em',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)'
            }
          }}
          onClick={clear}
        />
      )}
    </Box>
  );
});

const Tally = ({
  numPlayers,
  chestTotal,
  saleAmount,
  silverBagsTotal,
  booksTotal,
  salePercentage,
  guildCut,
  guildCutPercentage,
  juice,
  setJuice,
  totalSplit,
  perPlayer,
  donatedToGuild
}: {
  numPlayers: number;
  chestTotal: number;
  saleAmount: number;
  silverBagsTotal: number;
  booksTotal: number;
  salePercentage: number;
  guildCut: number;
  guildCutPercentage: number;
  totalSplit: number;
  juice: string | number;
  setJuice: (value: string) => void;
  perPlayer: number;
  donatedToGuild: number;
}) => {
  return (
    <>
      <TableRow sx={{ borderTop: '4px solid gray' }}>
        <TableCell align="right">CHEST SUBTOTAL</TableCell>
        <TableCell align="left">
          <SilverAmount amount={chestTotal} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="right">{salePercentage}% for Sale</TableCell>
        <TableCell align="left">
          <SilverAmount amount={saleAmount * -1} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="right">Silver Bags Total</TableCell>
        <TableCell align="left">
          <SilverAmount amount={silverBagsTotal} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="right">Books Total</TableCell>
        <TableCell align="left">
          <SilverAmount amount={booksTotal} />
        </TableCell>
      </TableRow>

      {guildCutPercentage ? (
        <TableRow>
          <TableCell align="right">{guildCutPercentage}% Guild Cut</TableCell>
          <TableCell align="left">
            <SilverAmount amount={guildCut * -1} />
          </TableCell>
        </TableRow>
      ) : null}

      <TableRow>
        <TableCell align="right">Adjustments</TableCell>
        <TableCell align="left">
          <SilverInputField
            amount={juice}
            id="juice"
            onChange={(_, value) => setJuice(value)}
            onDelete={() => setJuice('0')}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="right">Total Split</TableCell>
        <TableCell align="left">
          <SilverAmount amount={totalSplit} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="right">Number of Players</TableCell>
        <TableCell align="left">{numPlayers}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" colSpan={2} sx={{ backgroundColor: 'rgba(0,255,0,0.3)' }}>
          <h3>Silver per Player</h3>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" colSpan={2} sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>
          <SilverAmount amount={perPlayer} align="center" minWidth="0" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" colSpan={2} sx={{ backgroundColor: 'rgba(0,178,255,0.3)' }}>
          <h3>Donated to Guild</h3>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell align="center" colSpan={2} sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>
          <SilverAmount amount={donatedToGuild} align="center" minWidth="0" />
        </TableCell>
      </TableRow>
    </>
  );
};

const SilverAmount = ({
  amount,
  onClick,
  align = 'flext-start',
  minWidth = '82px'
}: {
  amount: number;
  onClick?: () => void;
  align?: string;
  minWidth?: string;
}) => (
  <Box
    sx={{ display: 'flex', gap: '3px', alignItems: 'center', justifyContent: align }}
    onClick={onClick}
  >
    <SilverIcon />
    <Box sx={{ minWidth }}>{new Intl.NumberFormat().format(amount || 0)}</Box>
  </Box>
);

const SilverIcon = ({ className, style }: { className?: string; style?: React.CSSProperties }) => (
  <img
    src="https://iqwgqnbvoejzaqceivdm.supabase.co/storage/v1/object/public/images/silver.png?t=2024-07-06T15%3A07%3A08.372Z"
    alt="Silver"
    style={{ width: '20px', ...style }}
    className={className}
  />
);
