import * as React from 'react';
import {
  Admin,
  CustomRoutes,
  defaultDarkTheme,
  Layout,
  Resource,
  Sidebar,
  SidebarProps,
  TitlePortal,
  SidebarToggleButton
} from 'react-admin';
import { AppBar, Avatar, Stack, Toolbar, Typography } from '@mui/material';
import restProvider from 'ra-data-simple-rest';
import { MemberEdit, MemberIcon, MemberList } from './pages/members/Members';
import { useIsLoggedIn } from '../../hooks/useIsLoggedIn';
import { Route } from 'react-router-dom';
import { GuildActivities } from './pages/activities/view/GuildActivities';
import { usePerms, useRole } from '../../hooks/usePerms';
import { hasPermission, oneOf, Permissions } from '../../../shared/Permissions';
import { MyMenu } from './components/menu/Menu';
import { Dashboard } from './pages/dashboard/Dashboard';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from '../../store/Store';
import { Comps } from './pages/comps/Comps';
import { RunEvent } from './pages/events/run/RunEvent';
import { CreateEvent } from './pages/events/create/CreateEvent';
import { ViewEvent } from './pages/events/view/ViewEvent';
import { deepmerge } from '@mui/utils';
import { Events } from './pages/events/Events';
import { useGetUserProfileQuery } from '../../store/api/User';
import { CreateComp } from './pages/comps/create/CreateComp';
import { ViewComp } from './pages/comps/view/ViewComp';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink } from '@trpc/client';
import { trpc } from './api/trpc';
import { useState } from 'react';
import { LoginPage } from './pages/login/LoginPage';
import { AdminPage } from './pages/admin/AdminPage';
import { ActivitySettings } from './pages/activities/settings/ActivitySettings';
import { EditEvent } from './pages/events/edit/EditEvent';
import { AccessDenied } from './pages/access-denied/AccessDenied';
import { Settings } from './pages/settings/Settings';
import { ManagePermissions } from './pages/permissions/ManagePermissions';

export const MembersArea = () => {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: '/api/trpc'
        })
      ]
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MembersAreaPage />
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </trpc.Provider>
  );
};

const theme = deepmerge(defaultDarkTheme, {
  components: {
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          // invisible border when not active, to avoid position flashs
          borderLeft: '5px solid transparent',
          padding: '6px 8px',
          '&.RaMenuItemLink-active': {
            borderLeft: '5px solid rgba(172, 15, 15, 0.7)'
          },
          '.RaMenu-closed &': {
            paddingLeft: '8px'
          }
        }
      }
    }
  }
});

const MembersAreaPage = () => {
  const isLoggedIn = useIsLoggedIn();
  const permissions = usePerms();

  if (isLoggedIn === null) {
    return 'Logging In...';
  }

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  if (!hasPermission(permissions, Permissions.ACCESS_DASHBOARD)) {
    return <AccessDenied />;
  }

  return (
    <Admin
      layout={MyLayout}
      dashboard={Dashboard}
      basename="/dashboard"
      dataProvider={restProvider('/api')}
      theme={theme}
      defaultTheme="dark"
    >
      {hasPermission(permissions, Permissions.VIEW_MEMBERS) && (
        <Resource name="members" list={MemberList} edit={MemberEdit} icon={MemberIcon} />
      )}

      <CustomRoutes>
        {/* Activities */}
        {hasPermission(permissions, Permissions.VIEW_ACTIVITIES) && (
          <Route path="/guild-activities" element={<GuildActivities />} />
        )}

        {hasPermission(permissions, Permissions.SET_OWN_ACTIVITY) && (
          <Route path="/guild-activities-settings" element={<ActivitySettings />} />
        )}

        {/* Comps/Builds */}
        {hasPermission(permissions, Permissions.CREATE_COMP) && (
          <Route path="/builds/:id/create" element={<CreateComp />} />
        )}

        {hasPermission(permissions, Permissions.VIEW_PUBLISHED_COMPS) && (
          <Route path="/builds/:id" element={<ViewComp />} />
        )}

        {hasPermission(permissions, Permissions.VIEW_PUBLISHED_COMPS) && (
          <Route path="/builds" element={<Comps />} />
        )}

        {/* Events */}
        {hasPermission(permissions, Permissions.VIEW_GUILD_EVENTS) && (
          <Route path="/events" element={<Events />} />
        )}

        {hasPermission(permissions, Permissions.VIEW_GUILD_EVENTS) && (
          <Route path="/events/:id" element={<ViewEvent />} />
        )}

        {hasPermission(
          permissions,
          oneOf([Permissions.RUN_OWN_EVENT, Permissions.RUN_ANY_EVENT])
        ) && <Route path="/events/:id/run" element={<RunEvent />} />}

        {hasPermission(
          permissions,
          oneOf([Permissions.EDIT_OWN_EVENT, Permissions.EDIT_ANY_EVENT])
        ) && <Route path="/events/:id/edit" element={<EditEvent />} />}

        {hasPermission(permissions, Permissions.CREATE_GUILD_EVENT) && (
          <Route path="/create-event" element={<CreateEvent />} />
        )}

        {/* Manage */}
        {hasPermission(permissions, Permissions.CHANGE_SETTINGS) && (
          <Route path="/settings" element={<Settings />} />
        )}

        {hasPermission(permissions, Permissions.MANAGE_PERMISSIONS) && (
          <Route path="/manage-permissions" element={<ManagePermissions />} />
        )}

        {/* Super Admin */}
        {hasPermission(permissions, Permissions.RUN_ADMIN_FUNCTIONS) && (
          <Route path="/admin" element={<AdminPage />} />
        )}
      </CustomRoutes>
    </Admin>
  );
};

const MyAppBar = () => {
  const { data: user } = useGetUserProfileQuery(undefined);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundImage: 'linear-gradient(to bottom, rgba(172, 15, 15, 1), rgba(133, 10, 10, 0.7))',
        borderBottom: '1px solid rgb(58, 4, 4)'
      }}
    >
      <Toolbar variant="dense" disableGutters sx={{ pl: '6px', pr: 3 }}>
        <SidebarToggleButton />
        <TitlePortal />
        {user && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar src={user.avatar} sx={{ width: 24, height: 24 }} />
            <Typography variant="body2">{user.username}</Typography>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};

export const MySidebar = ({ children, ...props }: SidebarProps) => {
  return (
    <Sidebar
      {...props}
      appBarAlwaysOn={true}
      sx={{ '& .RaSidebar-fixed': { backgroundColor: 'rgba(19,19,19,0.88)' } }}
    >
      {children}
    </Sidebar>
  );
};

const MyLayout = (props: any) => (
  <Layout {...props} sidebar={MySidebar} menu={MyMenu} appBar={MyAppBar} />
);
