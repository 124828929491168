export const DiscordEmoji = ({
  id,
  size = 32,
  alt = '',
  onClick,
  className,
  style
}: {
  id: string;
  size?: number;
  alt?: string;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <img
      src={`https://cdn.discordapp.com/emojis/${id}.webp?size=${size}&quality=lossless`}
      alt={alt}
      onClick={onClick}
      className={className}
      style={{ cursor: onClick ? 'pointer' : 'default', ...style }}
    />
  );
};
