import React from 'react';
import styles from './TitleBox.module.less';
import clsx from 'clsx';

export const TitleBox = ({
  children,
  className,
  style
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <div className={clsx(styles.titleBox, className)} style={style}>
      {children}
    </div>
  );
};
