import { ContentBox } from '../../components/ContentBox';
import { trpc } from '../../api/trpc';
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

export const Settings = () => {
  const utils = trpc.useUtils();
  const { data: voiceChannels } = trpc.discord.getAllVoiceChannels.useQuery();
  const { data: textChannels } = trpc.discord.getAllTextChannels.useQuery();
  const { data: allowedVoiceChannels } = trpc.discord.getAllowedVoiceChannels.useQuery();

  const invalidateGettingVCs = useDebouncedCallback(() => {
    utils.discord.getAllowedVoiceChannels.invalidate();
  }, 1000);

  const { mutateAsync: toggleChannels } = trpc.settings.toggleAllowedVoiceChannels.useMutation({
    onSuccess: () => {
      invalidateGettingVCs();
    },
    onMutate: data => {
      utils.discord.getAllowedVoiceChannels.setData(undefined, oldData => {
        return data.channel_ids.map(channel_id => {
          return {
            channel_id,
            name: voiceChannels?.find(c => c.id === channel_id)?.name || ''
          };
        });
      });
    }
  });

  return (
    <ContentBox>
      <h1>Settings</h1>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content 1fr'
        }}
      >
        <Box sx={{ m: 2 }}>
          <h3>Event Voice Channels</h3>
          <p>Voice Channels that may be selected when creating events.</p>
        </Box>

        <Box>
          <FormControl sx={{ width: 300 }}>
            <InputLabel id="allowed-voice-channels-label">Select Channels</InputLabel>
            <Select
              labelId="allowed-voice-channels-label"
              multiple
              value={allowedVoiceChannels?.map(value => value.channel_id) || []}
              input={<OutlinedInput id="allowed-voice-channels" label="Select Channels" />}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {allowedVoiceChannels?.map(value => (
                    <Chip key={value.channel_id} label={value.name} />
                  ))}
                </Box>
              )}
              onChange={async e => {
                await toggleChannels({
                  channel_ids: Array.isArray(e.target.value) ? e.target.value : [e.target.value]
                });
              }}
            >
              {voiceChannels?.map(channel => (
                <MenuItem key={channel.id} value={channel.id}>
                  {channel.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </ContentBox>
  );
};
