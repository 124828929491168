import { useState } from 'react';
import styles from './ManagePermissions.module.less';
import { trpc } from '../../api/trpc';
import clsx from 'clsx';
import { Paper, Switch } from '@mui/material';
import { Loader } from '../../../../components/loader/Loader';
import { useDebouncedCallback } from 'use-debounce';

export const ManagePermissions = () => {
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const { data: roles } = trpc.permissions.getRoles.useQuery();

  if (!roles) {
    return <Loader />;
  }

  return (
    <div className={styles.managePermissions}>
      <Paper elevation={1} className={styles.header}>
        <h1>Manage Permissions</h1>
      </Paper>

      <div className={styles.rolesList}>
        {roles.map(role => (
          <div
            key={role.id}
            className={clsx(styles.role, {
              [styles.selected]: selectedRole === role.id
            })}
            onClick={() => setSelectedRole(role.id)}
          >
            <div className={styles.roleColor} style={{ backgroundColor: role.color }} />
            <div className={styles.roleName}>{role.name}</div>
          </div>
        ))}
      </div>

      <div className={styles.permissionsList}>
        <PermissionsList selectedRole={roles.find(role => role.id === selectedRole)} />
      </div>
    </div>
  );
};

const PermissionsList = ({
  selectedRole
}: {
  selectedRole?: { id: string; name: string; position: number; color: string };
}) => {
  const utils = trpc.useUtils();
  const { data: permissions } = trpc.permissions.getPermissions.useQuery();
  const { data: rolePermissions } = trpc.permissions.getRolePermissions.useQuery();

  const togglePermission = trpc.permissions.setRolePermission.useMutation({
    onSuccess: useDebouncedCallback(() => {
      utils.permissions.getRolePermissions.invalidate();
    }, 2000),

    onMutate(input) {
      utils.permissions.getRolePermissions.setData(undefined, oldData => {
        if (!oldData) {
          return;
        }

        const oldRolePerms = oldData[input.discord_role_id] || [];
        const hasPerm = oldRolePerms.includes(input.permission);

        if (hasPerm) {
          return {
            ...oldData,
            [input.discord_role_id]: oldRolePerms.filter(p => p !== input.permission)
          };
        }

        return {
          ...oldData,
          [input.discord_role_id]: [...oldRolePerms, input.permission]
        };
      });
    }
  });

  if (!permissions || !rolePermissions) {
    return <Loader />;
  }

  if (!selectedRole) {
    return <p>Select a role to set permissions.</p>;
  }

  const selectedRolePermissions = rolePermissions[selectedRole.id] || [];

  return (
    <>
      <h1 className={clsx(styles.roleTitle)}>
        <div className={styles.roleColor} style={{ backgroundColor: selectedRole.color }} />
        <div className={styles.roleName}>{selectedRole.name}</div>
      </h1>
      {permissions.map(category => (
        <div key={category.category} className={styles.category}>
          <h3 className={styles.categoryName}>{category.category}</h3>
          <div className={styles.permissions}>
            {category.permissions.map(permission => (
              <div key={permission.permission} className={styles.permission}>
                <div className={styles.permissionTitle}>
                  <div className={styles.permissionName}>{permission.name}</div>
                  <Switch
                    checked={selectedRolePermissions.includes(permission.permission)}
                    onClick={() =>
                      togglePermission.mutate({
                        discord_role_id: selectedRole.id,
                        permission: permission.permission
                      })
                    }
                  />
                </div>
                <p>{permission.description}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
