import React from 'react';
import styles from './HeadingBox.module.less';

interface HeadingBoxProps {
  children: React.ReactNode;
}
export const HeadingBox = ({ children }: HeadingBoxProps) => {
  return (
    <div>
      <div className={styles.headingBox}>
        <img src="/heading/heading-left.png" height={55} alt="" />
        <h3 className={styles.text}>{children}</h3>
        <img src="/heading/heading-right.png" height={55} alt="" />
      </div>
    </div>
  );
};
